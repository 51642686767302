<template>
	<div class="newsletter">
		<transition name="slide-fade">
			<v-form ref="form" v-if="!status">
				<v-text-field
					v-model="first_name"
					name="first_name"
					dark
					label="Nome"
				></v-text-field>
				<v-text-field
					v-model="last_name"
					name="last_name"
					dark
					label="Cognome"
				></v-text-field>
				<v-text-field
					v-model="email"
					name="email"
					dark
					label="E-mail"
				></v-text-field>
				<Button
					:inverted="true"
					color="aili-blue"
					value="Iscriviti"
					:loading="loading"
					class="button float-right"
					@click="subscribe"
				/>

				<transition name="slide-fade">
					<div v-if="attempt && message">
						{{ message.message }}
					</div>
				</transition>
			</v-form>
		</transition>
		<transition name="slide-fade">
			<div class="success-subscribe" v-if="attempt && status">
				<svg viewBox="0 0 24 24">
					<path
						fill="currentColor"
						d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
					/>
				</svg>
				Complimenti, ti sei registrato alla Newsletter
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: "LazyblockArticleletterBlock",
	props: ["block", "blocks", "parentBlock"],

	data() {
		return {
			posts: null,
			first_name: null,
			last_name: null,
			email: null,
			status: false,
			attempt: false,
			message: null,
			loading: false
		};
	},
	methods: {
		async subscribe() {
			this.loading = true;
			this.attempt = true;
			let response = await this.$api.post("/qzr-data/subscribe", {
				first_name: this.first_name,
				last_name: this.last_name,
				email: this.email
			});
			this.status = response.data.success;
			if(response.data.msg){
				this.message = JSON.parse(
					response.data.msg.replace(
						"[400] Client error: `POST https://api.sendinblue.com/v3/contacts` resulted in a `400 Bad Request` response:",
						""
					)
				);
			}
			this.loading = false;
			if(this.message.message)
			switch(this.message.message){
				case 'Please provide Email or SMS attribute value to create user':
					this.message.message = 'Compila tutti i campi per piacere';
				break;
				case 'Contact already exist':
					this.message.message = 'Sembra che questa email sia già presente';
				break;

			}
		}
	}
};
</script>

<style lang="scss" scoped>
.row {
	padding: $spacing-0;
}
.newsletter {
	position: relative;
	min-height: 250px;
}
.success-subscribe {
	position: absolute;
	top: 50%;
	left: 50%;
	bottom: 0;
	transform: translate(-50%, -50%);
	svg {
		width: 100%;
		height: 48px;
		margin-bottom: 10px;
	}
}
</style>
