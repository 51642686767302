<template>
	<Button :href="block.attrs.url" :value="block.attrs.text" />
</template>

<style lang="scss" scoped></style>

<script>
import Button from "@/components/ui/Button.vue";

export default {
	name: "core_button",
	props: ["block"],
	components: {
		Button
	}
};
</script>
