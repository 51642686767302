<template>
	<div class="carousel_list" v-if="cards.length > 0">
		<v-container class="slider">
			<swiper @slideChange="onSlideChange" class="swiper-partners" ref="swiperPartner" :options="swiperOption">
				<swiper-slide v-for="(card, i) in cards" :key="i">
					<a class="partner-card" :title="card.name" target="_blank" :href="card.url"
						><img
							:src="
								card.image && card.image.sizes && card.image.sizes.medium
									? card.image.sizes.medium.url
									: []
							"
					/></a>
				</swiper-slide>
				<div
					v-if="
						$vuetify.breakpoint.width < 768 ||
							($vuetify.breakpoint.width < 1024 && cards.length > 3) ||
							cards.length > 4
					"
					class="swiper-pagination"
					slot="pagination"
				></div>
			</swiper>
			<br class="clear" />
		</v-container>
	</div>
</template>

<script>
export default {
	props: ['block', 'blocks', 'parentBlock'],
	data() {
		return {
			currentIndex: 0,
			cards: [],
			loaded: false,
			swiperOption: {
				slidesPerView: 1,
				spaceBetween: 60,
				centeredSlides: true,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				breakpoints: {
					1024: {
						slidesPerView: 4,
						spaceBetween: 40,
					},
					768: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
				},
			},
		};
	},
	mounted() {
		let cards = this.parseAttribute(this.block.attrs.partners);
		cards.map((element) => {
			if (element.image && element.image.sizes && !element.image.sizes.medium) {
				element.image.sizes['medium'] = { url: element.image.sizes.full.url };
			}
			if (element.image && !element.image.sizes) {
				element.image.sizes = [];
				element.image.sizes['medium'] = { url: element.image.url };
			}

			return element;
		});
		this.cards = cards;
		this.loaded = true;
	},
	computed: {
		swiper() {
			return this.$refs.swiperPartner.$swiper;
		},
	},
	methods: {
		onSlideChange() {
			this.currentIndex = this.swiper.activeIndex;
		},
		changeUrl(url) {
			window.location.href = url;
		},
		onPaginationClick(index) {
			this.swiper.slideTo(index);
		},
		nextPage() {
			this.swiper.slideNext();
		},
		prevPage() {
			this.swiper.slidePrev();
		},
	},
};
</script>

<style lang="scss" scoped>
.carousel_list {
	position: relative;
	margin-top: $spacing-0;
}

.swiper-pagination {
	position: relative;
	display: inline-block;
	margin-top: 25px;
}
#app {
	.swiper-container {
		padding: 0 !important;
	}
}

.swiper-partners {
	right: 0;
	width: 100%;
	text-align: left;
	padding: 50px 0;

	@media (max-width: $tablet-m) {
		margin-left: 0;
		width: 100%;
		text-align: center;
	}
}

img {
	width: 200px;
}

.buttons {
	width: 50px;
	position: relative;
	position: absolute;
	top: 50px;
}

.card {
	padding: $spacing-0;
}
</style>
