<template>
	<div>
		<component
			v-if="block"
			:class="{
				['color-' + block.attrs.textColor]: true,
				[block.attrs.fontSize]: true,
				[block.attrs.className
					? block.attrs.className
					: 'no-style']: true
			}"
			v-bind:is="'h' + block.attrs.level"
			v-html="block.attrs.text"
		></component>
	</div>
</template>

<style lang="scss" scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
	min-height: 1rem;
	padding-bottom: $spacing-0;
}

h2 {
	margin-bottom: 0;
}

h3 {
	margin-bottom: 0;
}

h4 {
	font-size: $font-size-20;
	font-weight: bold;
}

</style>

<script>
export default {
	name: "CoreHeadingBlock",
	props: ["block"]
};
</script>
