<template>
	<div class="lazyblock-google-maps">
		<GoogleMapLoader
			v-if="selectedMarkers"
			apiKey="AIzaSyD55LtBrWHlrDnjuS8_4adKI-P6MK79o_w"
			height="740px"
			class="maps"
			:markers="selectedMarkers"
			:disabledUI="true"
		>
			<template slot-scope="{ google, map }">
				<GoogleMapMarker
					ref="googleMapMarkers"
					v-for="marker in selectedMarkers"
					:key="marker.id"
					:marker="marker"
					:google="google"
					:map="map"
				/>
			</template>
		</GoogleMapLoader>
		<MainColumn class="card-wrapper">
			<div class="card color-aili-white">
				<v-img class="image" :src="parseAttribute(block.attrs.image).url" />

				<RenderBlocks :blocks="block.innerBlocks" :parentBlock="block" />

				<Button
					v-for="(button, i) in parseAttribute(block.attrs.buttons)"
					:key="i"
					:value="button.label"
					:border="button.border"
					font_size="tiny"
					width="full"
				/>
			</div>
		</MainColumn>
	</div>
</template>

<script>
import GoogleMapLoader from '@/components/GoogleMap/GoogleMapLoader.vue';
import GoogleMapMarker from '@/components/GoogleMap/GoogleMapMarker.vue';
import Button from '@/components/ui/Button.vue';

export default {
	name: 'lazyblock_google_maps',
	props: ['block'],
	components: {
		GoogleMapLoader,
		GoogleMapMarker,
		RenderBlocks: () => import('@/components/blocks/RenderBlocks.vue'),
		Button,
	},
	data() {
		return {
			selectedMarkers: [],
			addresses: [],
		};
	},
	async mounted() {
		let maxPages = null;
		for (let currentPage = 1; currentPage <= maxPages || !maxPages; currentPage++) {
			let responseAddresses = await this.$api.get('/wp/v2/address', {
				params: {
					page: currentPage,
					per_page: 100,
					'address-category': 17,
				},
			});
			this.addresses = this.addresses.concat(responseAddresses.data);
			if (!maxPages) {
				maxPages = responseAddresses.headers['x-wp-totalpages'];
			}
		}
		this.addresses.map((address) => {
			this.selectedMarkers.push({
				position: JSON.parse(address.meta_address.location).results[0].geometry.location,
				id: address.slug,
				title: address.title.rendered,
				description: address.meta_address.content,
				tel: address.meta_address.tel,
				email: address.meta_address.email,
				www: address.meta_address.www,
				icon: address.meta_address.icon ? address.meta_address.icon : '',
			});
		});
	},
};
</script>

<style lang="scss" scoped>
.lazyblock-google-maps {
	@media (max-width: $tablet-m) {
		display: flex;
		flex-direction: column;
	}

	.maps {
		order: 2;
	}
}

#app .card-wrapper {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%);
	order: 1;

	.card {
		display: inline-block;
		background: $aili-yellow;
		z-index: 1;
		position: absolute;
		padding: 50px;
		top: 50%;
		transform: translateY(-50%);
		width: 40%;
		min-width: 420px;

		* {
			color: #fff !important;
		}

		@media (max-width: $tablet-m) {
			min-width: auto;
			position: relative;
			top: 0;
			left: 0;
			padding: $spacing-1 5%;
			width: 100%;
			display: inline-block;
			transform: none;
		}
	}

	@media (max-width: $tablet-m) {
		transform: none;
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		display: inline-block;
	}
}

#app .image {
	width: 70%;
	max-width: 200px;
	margin: auto;
	margin-bottom: 50px;
}

.lazyblock-google-maps {
	position: relative;
	width: 100%;
	min-height: 680px;
	@media (max-width: $tablet-m) {
		height: auto;
	}
}
</style>
