<template>
	<v-lazy min-height="200" transition="slide-up">
		<v-card tile class="card-container content-shadow" ref="card">
			<v-row no-gutters>
				<v-col offset="1" cols="8">
					<h2 :class="'font-size-25 color-' + pageColor" v-html="block.attrs.title" />
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col offset="1" cols="10">
					<v-list disabled>
						<v-list-item-group>
							<template v-for="(item, i) in listItem">
								<v-list-item :key="item + '-' + i" class="customListItem">
									<v-list-item-content>
										<v-list-item-title v-html="item" />
									</v-list-item-content>
								</v-list-item>
								<v-divider :class="'background-' + pageColor" :key="i"></v-divider>
							</template>
						</v-list-item-group>
					</v-list>
				</v-col>
			</v-row>
			<RenderBlocks class="render-blocks" :blocks="this.block.innerBlocks" />
		</v-card>
	</v-lazy>
</template>

<script>
export default {
	props: ['block', 'blocks', 'parentBlock'],
	components: {
		RenderBlocks: () => import('@/components/blocks/RenderBlocks.vue'),
	},
	data() {
		return {
			listItem: [],
		};
	},
	mounted() {
		let listItem = decodeURI(this.block.attrs.listpanel);
		listItem = JSON.parse('[' + listItem + ']')[0];
		this.listItem = listItem.map((item) => item.description);
	},
};
</script>

<style lang="scss" scoped>
h2 {
	width: 100%;
}
.card-container {
	margin-top: $spacing-1;
	padding-top: $spacing-1;
	position: relative;
	width: 100%;
	max-width: 600px;
	margin-bottom: 50px;
}
.render-blocks {
	margin-top: $spacing-1;
	min-height: 1px;
}

.v-list-item__title,
.v-list-item__subtitle {
	white-space: unset;
}

.v-list-item__content .v-list-item__title {
	line-height: 1.4;
}
</style>
