<template>
	<div></div>
</template>
<script>
export default {
	props: {
		google: {
			type: Object,
			required: true,
		},
		map: {
			type: Object,
			required: true,
		},
		marker: {
			type: Object,
			required: true,
		},
		icon: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			markerInstance: null,
			listData: '',
		};
	},
	mounted() {
		this.listData += "<div style='color: black; max-width: 250px; line-height: 1.2'>";
		this.listData +=
			'<p style="text-transform: uppercase; font-size: 16px; font-weight:bold ">' + this.marker.title + '</p>';
		this.listData += '<p>' + this.marker.description + '</p>';
		this.listData += '<hr style="margin-bottom: 16px"></hr>';

		for (let info in this.marker) {
			if (this.marker[info] && this.marker[info] !== '' && this.marker[info] !== 'None') {
				switch (info) {
					case 'www':
						this.listData +=
							'<p style="font-weight: bold;  display: inline-block; margin-bottom: 6px">Sito web:</p>' +
							' <a target="_blank" href="' +
							this.marker[info] +
							'">apri »</a><br />';
						break;

					case 'tel':
						this.listData +=
							'<p style="font-weight: bold;  display: inline-block; margin-bottom: 6px">Tel:</p>' +
							' <a target="_blank" href="tel:' +
							this.marker[info] +
							'">' +
							this.marker[info] +
							'</a><br />';
						break;

					case 'email':
						this.listData +=
							'<p style="font-weight: bold;  display: inline-block; margin-bottom: 6px">E-mail:</p>' +
							' <a target="_blank" href="mailto:' +
							this.marker[info] +
							'">' +
							this.marker[info] +
							'</a><br />';
						break;

					default:
						break;
				}
			}
		}
		const infoWindow = new this.google.maps.InfoWindow({
			content: this.listData,
		});
		this.markerInstance = new this.google.maps.Marker({
			position: this.marker.position,
			marker: this.marker,
			title: this.marker.label,
			animation: this.google.maps.Animation.DROP,
			map: this.map,
			icon: {
				url: this.icon ? this.icon : '',
				size: new this.google.maps.Size(41, 41),
				scaledSize: new this.google.maps.Size(30, 30),
				origin: new this.google.maps.Point(0, 0),
			},
		});
		const vueInstance = this;
		this.markerInstance.addListener('click', () => {
			if (this.isInfoWindowOpen(infoWindow)) {
				infoWindow.close();
			} else {
				vueInstance.$emit('onMarkerClick', vueInstance);
				infoWindow.open(this.map, this.markerInstance);
			}
		});
	},
	methods: {
		destroyMarker() {
			this.markerInstance.setMap(null);
		},
		isInfoWindowOpen(infoWindow) {
			var map = infoWindow.getMap();
			return map !== null && typeof map !== 'undefined';
		},
	},
};
</script>
