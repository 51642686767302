<template>
	<v-form v-model="valid">
		<v-container>
			<slot name="formFields"></slot>

			<v-row no-gutters class="card-row">
				<v-col md="3" cols="12" align-self="center">
					<v-text-field
						:color="buttonColor"
						:rules="donationRules"
						v-model="donation"
						type="Number"
						label="Importo(€)"
						required
					></v-text-field>
				</v-col>
				<v-col :md="fullWidth ? 5 : 8" cols="12" :offset-md="1" align-self="center" class="field">
					<div id="card"></div>
				</v-col>
				<v-col cols="2" offset="4" align-self="center" :offset-md="fullWidth ? 1 : 0" class="field">
					<v-btn
						class="donation-button"
						:color="buttonColor"
						depressed
						:disabled="!donateButtonEnabled"
						:loading="loading"
						@click="onDonationClick"
						>{{ buttonText }}</v-btn
					>
				</v-col>
			</v-row>

			<v-row no-gutters>
				<v-col cols="12">
					<div class="text-center" v-if="errorCard">{{ errorCard.message }}</div>
				</v-col>
			</v-row>
		</v-container>
	</v-form>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
export default {
	props: {
		fullWidth: {
			type: Boolean,
			default: true,
			required: false,
		},
		donationRules: {
			type: Array,
			default: () => [],
			required: false,
		},
		infoToSaveInStripe: {
			type: Object,
			default: null,
			required: false,
		},
		billingInfo: {
			type: Object,
			default: null,
			required: false,
		},
		buttonText: {
			type: String,
			default: 'Iscriviti',
			required: false,
		},
		buttonColor: {
			type: String,
			default: 'white',
			required: false,
		},
		paymentType: {
			type: String,
			default: '',
			required: true,
		},
		url: {
			type: String,
			default: '/qzr-data/donate',
			required: false,
		},
		dimension: {
			type: Object,
			default: null,
			required: false,
		},
		loading: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	data: () => ({
		donation: '',
		card: null,
		completeCard: false,
		elements: null,
		errorCard: null,
		publishableKey: process.env.VUE_APP_STRIPE_PK,
		stripe: null,
		valid: false,
	}),
	computed: {
		donateButtonEnabled() {
			return this.completeCard && !this.errorCard && this.valid;
		},
	},
	async mounted() {
		this.stripe = await loadStripe(this.publishableKey);
		this.elements = this.stripe.elements();
		this.card = this.elements.create('card');
		this.card.on('change', ({ complete, error }) => {
			this.completeCard = complete;
			this.errorCard = error;
		});
		this.card.mount('#card');
	},
	methods: {
		async onDonationClick() {
			this.$emit('paymentStarted');
			let response = await this.$api.post(this.url, {
				...this.infoToSaveInStripe,
				amount: this.donation,
				payment_type: this.paymentType,
				billing_info: this.billingInfo,
			});
			if (response.data.error) {
				this.errorCard = response.data.error;
				return;
			}
			let client_secret = response.data;
			let options = {
				payment_method: {
					card: this.card,
				},
			};
			if (this.billingInfo) {
				options.payment_method['billing_details'] = {
					name: this.billingInfo.other_data.first_name + ' ' + this.billingInfo.other_data.last_name,
				};
			}
			let result = await this.stripe.confirmCardPayment(client_secret, options);

			if (result.error) {
				this.$emit('paymentError', result.error);
			} else {
				this.$emit('paymentCompleted', result.paymentIntent.id);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.field {
	@media (max-width: $tablet-l) {
		padding-bottom: $spacing-0;
	}
}
</style>
