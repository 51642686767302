<template>
	<video-embed :src="block.attrs.url"></video-embed>
</template>

<script>
export default {
	props: ['block', 'parentBlock'],
	methods: {
		getId(url) {
			return this.$youtube.getIdFromUrl(url);
		},
	},
};
</script>

<style lang="scss">
.embed-responsive iframe {
	border: 0;
	@media (max-width: $tablet-l) {
		height: 300px;
		width: 100%;
	}
	@media (min-width: $desktop) {
		height: 500px;
		width: 80%;
	}
}
</style>
