<template>
	<div class="block-core-columns">
		<div
			v-for="(column, i) in block.innerBlocks"
			:key="i"
			class="single-column"
			:style="
				'width:' +
					(column.attrs.width ? column.attrs.width : defaultWidth) +
					(column.attrs.width && column.attrs.width.indexOf('%') > -1 ? '' : '%')
			"
		>
			<RenderBlocks
				:blocks="column.innerBlocks"
				:parentBlock="parentBlock"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: "CoreColumnsBlock",
	props: ["block", "parentBlock"],
	components: {
		RenderBlocks: () => import("@/components/blocks/RenderBlocks.vue")
	},
	data() {
		return {
			defaultWidth: null
		};
	},
	mounted() {
		let remaningSpace = 100;
		let countingUnsets = this.block.innerBlocks.length;
		this.block.innerBlocks.map(function(value) {
			if (value.attrs.width) {
				remaningSpace = remaningSpace - value.attrs.width;
				countingUnsets--;
			}
		});
		this.defaultWidth = remaningSpace / countingUnsets;
	}
};
</script>

<style lang="scss" scoped>
.block-core-columns {
	display: flex;

	@media (max-width: $tablet-m) {
		display: inline-block;
		width: 100%;
	}

	.single-column {
		padding-right: $spacing-1;

		@media (max-width: $tablet-m) {
			display: inline-block;
			padding-right: 0;
			width: 100% !important;
			margin-bottom: 15px;
		}
	}

	.single-column:last-child {
		padding: 0;
	}
}
</style>
