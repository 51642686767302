<template>
	<div class="library" v-if="block.attrs.books">
		<h4 class="font-size-12">Bibliografia</h4>
		<div class="list">
			<div v-html="block.attrs.books"></div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["block", "blocks", "parentBlock"]
};
</script>

<style lang="scss" scoped>
.library {
	border-top: 1px solid #999;
	padding-top: 45px;
}
.list * {
	color: #999;
	font-size: 12px;
}
h3 {
	margin-bottom: $spacing-0;
	text-transform: uppercase;
	font-weight: bold;
}

li,
h4 {
	color: #999;
}
h4 {
	margin-bottom: $spacing-0;
}
</style>
