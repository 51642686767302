<template>
	<div></div>
</template>

<script>
export default {
	name: "lazyblock_subtitle",
	props: ["block", "blocks", "parentBlock"],
	components: {},
	data() {
		return {};
	}
};
</script>

<style lang="scss" scoped></style>
