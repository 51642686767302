var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-lazy',{attrs:{"transition":"fade-transition"}},[_c('div',{staticClass:"render-blocks"},_vm._l((_vm.blocks),function(block,i){return _c('div',{key:i,class:block.name
					.split('/')
					.join('_')
					.split('-')
					.join('_')},[(block.name !== undefined)?_c(block.name
						.split('/')
						.join('_')
						.split('-')
						.join('_'),{tag:"component",attrs:{"block":block,"parentBlock":_vm.parentBlock ? _vm.parentBlock : false,"offset":_vm.offset}}):_vm._e()],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }