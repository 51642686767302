<template>
	<div>
		<div :style="{ height: height }" ref="googleMap"></div>
		<template v-if="Boolean(this.google) && Boolean(this.map)">
			<slot :google="google" :map="map" v-bind:options="mapStyle" />
		</template>
	</div>
</template>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";
import json from "@/json/maps.json";

export default {
	name: "GoogleMapLoader",
	props: ["markers", "enableSearch", "height", "disabledUI"],
	data() {
		return {
			apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
			google: null,
			items: null,
			map: null,
			mapStyle: null,
			mapConfig: {
				zoom: 8,
			},
			selectedMunicipality: null,
			search: null,
		};
	},
	async mounted() {
		this.mapStyle = json;

		//let position = await this.getPosition();
		let pos = {
			lat: 41.9027835,//position.coords.latitude,
			lng: 12.4963655,//position.coords.longitude,
		};
		this.mapConfig.center = pos;
		const googleMapApi = await GoogleMapsApiLoader({
			libraries: ["places", "geometry"],
			apiKey: this.apiKey,
		});
		this.google = googleMapApi;
		this.initializeMap();
	},
	methods: {
		/*getPosition(options) {
			return new Promise(function(resolve, reject) {
				navigator.geolocation.getCurrentPosition(resolve, reject, options);
			});
		},*/
		initializeMap() {
			const mapContainer = this.$refs.googleMap;
			if (this.disabledUI) {
				this.mapConfig["disableDefaultUI"] = true;
				this.mapConfig["options"] = {
					scaleControl: false,
					scrollwheel: false,
					mapTypeControl: false,
					draggable: false,
				};
			}
			this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
			let bounds = new this.google.maps.LatLngBounds();
			setTimeout(() => {
				if (this.markers) {
					this.markers.map((marker) => {
						bounds.extend(marker.position);
					});
					this.map.fitBounds(bounds);
				}
			}, 1000);
		},
		onChangeMunicipality(lat, lng) {
			this.setCenter(lat, lng);
			this.map.setZoom(12);
		},
		setCenter(lat, lng) {
			this.map.panTo(new this.google.maps.LatLng(lat, lng));
		},
	},
};
</script>

<style scoped></style>
