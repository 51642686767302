var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.block.attrs.text)?_c('p',{class:( _obj = {}, _obj['a-' + _vm.color] = true, _obj[_vm.block.attrs.className
				? _vm.block.attrs.className
				: 'font-size-18'] = true, _obj[_vm.block.attrs.className == 'is-style-ask'
				? 'font-size-18 color-aili-black background-' +
				  _vm.pageColor +
				  '-light'
				: ''] = true, _obj[_vm.block.attrs.className == 'is-style-answer'
				? 'color-aili-black right'
				: ''] = true, _obj ),domProps:{"innerHTML":_vm._s(_vm.block.attrs.text)}}):_vm._e(),(_vm.block.attrs.className == 'is-style-answer')?_c('br',{staticClass:"clear"}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }