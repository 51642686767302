<template>
	<div>
		<p
			v-if="block.attrs.text"
			:class="{
				['a-' + color]: true,
				[block.attrs.className
					? block.attrs.className
					: 'font-size-18']: true,
				[block.attrs.className == 'is-style-ask'
					? 'font-size-18 color-aili-black background-' +
					  pageColor +
					  '-light'
					: '']: true,
				[block.attrs.className == 'is-style-answer'
					? 'color-aili-black right'
					: '']: true
			}"
			v-html="block.attrs.text"
		></p>
		<br class="clear" v-if="block.attrs.className == 'is-style-answer'" />
	</div>
</template>

<script>
export default {
	name: "core_paragraph",
	props: ["block", "parentBlock"],
	computed: {
		color() {
			let color;
			if (this.block.attrs.textColor) {
				color = this.block.attrs.textColor;
			} else if (
				this.parentBlock &&
				this.parentBlock.attrs &&
				this.parentBlock.attrs.color
			) {
				color = JSON.parse(this.parentBlock.attrs.color).color;
			}
			return color;
		}
	}
};
</script>

<style lang="scss" scoped>
p {
	max-width: 750px;
	margin-bottom: 20px;
}
.v-main .core_paragraph a:hover {
	color: black !important;
}
.a-aili-black a,
.a-aili-blue a,
.a-aili-green a,
.a-aili-pink a,
.a-aili-purple a,
.a-aili-yellow a {
	border-bottom: 1px solid;
	text-decoration: none;
	transition: color 0.3s;
}
.a-aili-blue a:hover,
.a-aili-green a:hover,
.a-aili-pink a:hover,
.a-aili-purple a:hover,
.a-aili-yellow a:hover {
	color: black !important;
}

.a-aili-black,
.a-aili-black a {
	color: #000;
}
.a-aili-blue,
.a-aili-blue a {
	color: #00aeef;
}
.a-aili-yellow,
.a-aili-yellow a {
	color: #ff8500;
}
.a-aili-pink,
.a-aili-pink a {
	color: #f72585;
}
.a-aili-purple,
.a-aili-purple a {
	color: #7209b7;
}
.a-aili-green,
.a-aili-green a {
	color: #00cca0;
}

.is-style-disclaimer {
	font-size: $font-size-12;
}
.is-style-answer {
	float: right;
	background: #ebebeb;
	border-radius: 24px;
	border-top-right-radius: 0;
	box-shadow: 0 0 27px -15px rgba(0, 0, 0, 0.3);
	padding: 25px;
}
.is-style-ask {
	box-shadow: 0 0 27px -15px rgba(0, 0, 0, 0.3);
	padding: 25px;
	border-radius: 24px;
	border-top-left-radius: 0;
}

.is-style-emphasis {
	padding: 25px;
	padding-left: 50px;
	margin: 25px 0;
	border-left: 10px solid;
	font-size: 18px;
}
</style>
