<template>
	<div>
		<RenderBlocks :blocks="block.innerBlocks" :parentBlock="block" />
	</div>
</template>

<script>
export default {
	props: ["block", "blocks", "parentBlock"],
	components: {
		RenderBlocks: () => import("@/components/blocks/RenderBlocks.vue")
	}
};
</script>

<style lang="scss" scoped></style>
