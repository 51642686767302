<template>
	<div class="image">
		<div
			:style="'background-image:url(' + block.attrs.url + ')'"
			v-if="block.attrs.className == 'is-style-wave'"
			class="imageWave"
		>
			<Wave v-if="color" class="wave" :color="color" position="top" />
		</div>
		<img
			v-else
			:class="block.attrs.className ? block.attrs.className : ''"
			:src="block.attrs.url"
			:style="{
				'margin-bottom': block.attrs.caption ? '0' : '25	px'
			}"
		/>
		<div class="caption-text" v-if="block.attrs.caption">
			{{ block.attrs.caption }}
		</div>
	</div>
</template>

<style lang="scss" scoped></style>

<script>
	import Wave from "@/components/ui/Wave.vue";
	export default {
		name: "core_image",
		props: ["block"],
		components: {
			Wave
		},
		data() {
			return {
				color: false
			};
		},
		mounted() {
			switch (this.pageColor) {
				case "aili-green":
					this.color = "#ccf8ee";
					break;
				case "aili-yellow":
					this.color = "#ffe7cc";
					break;
				case "aili-purple":
					this.color = "#e3cdf1";
					break;
				case "aili-pink":
					this.color = "#fed3e7";
					break;
				default:
					this.color = "#bfedff";
			}
		}
	};
</script>

<style scoped lang="scss">
	.caption-text {
		margin-bottom: $spacing-0 0;
		color: #999;
		font-size: $font-size-12;
		text-align: center;
		background-color: $aili-white;
	}
	.image {
		position: relative;
	}
	.imageWave {
		display: inline-block;
		width: 100%;
		padding-top: 50%;
		margin-bottom: -10px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
	img.is-style-wave {
		padding: 0;
		margin: 0;
		margin-top: -50px;
		margin-bottom: -10px;
	}
	img {
		width: 100%;
		max-width: 100%;
		margin-top: $spacing-0;
	}
</style>
