<template>
	<div class="subscription-form">
		<v-slide-y-reverse-transition mode="out-in">
			<div key="form" v-if="!loginData.user.data">
				<Form @onSubscribeClick="onSubscribeClick" :loading="loading_form" />

				<div class="error-message" v-if="error_message">
					{{ error_message }}
				</div>
			</div>

			<div key="donation" v-else>
				<div class="instructions font-size-20">
					La quota associativa minima è di € {{ minimum_donation }} e ti permette di ottenere la tessera AILI
					con validità annuale.
				</div>

				<Donation
					paymentType="donation_for_subscription"
					@paymentStarted="paymentStarted"
					@paymentCompleted="paymentCompleted"
					@paymentError="paymentError"
					:billingInfo="required_billing_info"
					:donationRules="valid_donation"
					:infoToSaveInStripe="info"
					:loading="loading_donation"
					buttonText="Associati"
					buttonColor="aili-blue"
					fullWidth
				>
				</Donation>

				<div class="error-message" v-if="donation_error">
					{{ donation_error }}
				</div>
			</div>
		</v-slide-y-reverse-transition>
		<div>
			<Confetti :boom="donation_success" />
			<v-dialog persistent v-model="toggleModal" max-width="500px">
				<v-fade-transition mode="out-in">
					<div class="success-donation" :class="`background-aili-blue color-aili-white`">
						<div class="success-donation-header">
							<h2 data-v-93fc608a="" class="color-undefined chantal no-style">
								Wow! Ora fai parte della nostra grande Famiglia!
							</h2>
						</div>
						<div class="success-donation-content color-aili-blue">
							L'iscrizione è andata a buon fine, la tua tessera è attiva e hai diritto a tutti i vantaggi
							da socio. Grazie per aver creduto in AILI, saremo sempre dalla tua parte!<br
								class="clear"
							/>
							<Button
								class="goto-profile"
								:color="pageColor"
								@click="goTo('Page', { slug: 'area-riservata' })"
								value="Vai al tuo profilo"
							/>
						</div>
					</div> </v-fade-transition
			></v-dialog>
		</div>
	</div>
</template>

<script>
import Form from '@/components/ui/Form.vue';
import Confetti from '@/components/ui/Confetti.vue';
import Donation from '@/components/ui/donation/Donation.vue';
import { login } from '@/components/ui/authentication/Login';
import goTo from 'vuetify/es5/services/goto';

export default {
	props: ['block', 'blocks', 'parentBlock'],
	components: {
		Form,
		Confetti,
		Donation,
	},
	mixins: [login],
	data() {
		return {
			info: null,
			error_message: null,
			donation_error: null,
			loading_form: false,
			loading_donation: false,
			valid_donation: [(v) => !!v || 'La donazione è obbligatoria'],
			donation_success: false,
		};
	},
	computed: {
		toggleModal() {
			return this.donation_success;
		},
		required_billing_info() {
			return this.loginData && this.loginData.user && this.loginData.user.data
				? {
						other_data: {
							first_name: this.loginData.user.data.first_name[0],
							last_name: this.loginData.user.data.last_name[0],
						},
						billing_details: {
							address: {
								country: 'IT',
								city: this.loginData.user.data.municipality[0],
								postal_code: this.loginData.user.data.cap[0],
								state: '',
								line1: this.loginData.user.data.address[0],
								line2: '',
							},
						},
						vat_fields: {
							fiscal_code: this.loginData.user.data.fiscal_code[0],
							vat_number: '',
							pecsdi: '',
							company: '',
						},
				  }
				: null;
		},
	},
	async mounted() {
		//Se sono già un socio attivo redirect al profilo
		console.log(this.loginData);
		if (this.user_status == 'active') {
			this.goTo('Page', { slug: 'area-riservata' });
		}

		let response_donation = await this.$api.get('/qzr-data/get_minimum_donation');
		this.minimum_donation = parseInt(response_donation.data, 10);
		this.valid_donation.push(
			(v) => parseInt(v) >= this.minimum_donation || 'La donazione minima è ' + this.minimum_donation
		);
	},
	methods: {
		paymentStarted() {
			this.loading_donation = true;
			this.donation_error = null;
		},
		paymentCompleted(payment_intent_id) {
			let donation_status = setInterval(async () => {
				let response = await this.$api.post('/qzr-data/get_payment_status', {
					payment_intent_id: payment_intent_id,
				});
				if (response.data === 'success') {
					clearInterval(donation_status);
					this.loading_donation = false;
					goTo(0);
					this.donation_success = true;
				}
			}, 1000);
		},
		paymentError(error) {
			this.donation_error = error.message;
			this.loading_donation = false;
		} /*
		async login(user = '', pass = '') {
			let login_data = {
				user: user,
				pass: pass,
			};
			try {
				let response = await this.$api.post('qzr-data/login', login_data);
				await this.getToken(response.data);
			} catch {
				await this.invalidLogin('Credenziali non valide');
			}
		},*/,
		async onSubscribeClick(data) {
			this.loading_form = true;
			this.error_message = null;

			data['role'] = 'member';
			data['type'] = 'base';
			data['scope'] = JSON.stringify('all');
			data['username'] = data['email'];

			let response = await this.$api.post('/qzr-data/save-fields', data);

			if (response.data.result == 'error') {
				this.error_message = Object.values(response.data.data.errors)[0][0];
				this.loading_form = false;
			}

			response = response.data.result && response.data.result == 'error' ? false : response.data;

			if (response) {
				await this.login(data.email, data.password);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep #card {
	padding: 10px 20px 11px;
	background-color: $aili-blue-light;
	border-radius: 20px;
	width: 100%;
	input {
		color: white;
	}
}
::v-deep .card-row {
	margin: $spacing-0 0;
}
::v-deep .donation-button {
	.v-btn__content {
		color: $aili-white;
	}
	letter-spacing: normal;
	border-radius: 90px;
	transition: all 0.3s;
	font-size: $font-size-14;
}
.success-donation {
	position: relative;
	display: inline-block;
	overflow-y: hidden;
	transition: all 0.5s;
	margin-bottom: -6px;
	width: 100%;

	h2 {
		margin: 0;
		padding: 0;
	}
	.success-donation-header {
		padding: 25px;
		position: relative;
	}
	.success-donation-content {
		padding: 25px;
		padding-bottom: 50px;
		background: #fff;
		position: relative;
		.goto-profile {
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			top: 25px;
			bottom: 25px;
		}
	}
}
.success-login {
	align-items: center;
	display: flex;
	justify-content: center;
	height: 100px;
	overflow-y: hidden;
	transition: all 0.5s;
	width: 100%;
}
.error-message {
	background-color: #ff0087;
	color: white;
	padding: 25px;
}
.instructions {
	max-width: 750px;
	margin-top: $spacing-0;
}
</style>
