<template>
	<div v-if="boom" class="confetti">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			preserveAspectRatio="xMidYMid"
			:viewBox="
				$vuetify.breakpoint.width > 599
					? '0 0 1500 1500'
					: '0 0 800 1500'
			"
		>
			<g v-for="(el, i) in confetti" :key="i">
				<g :transform="el.transform2">
					<g class="ld ld-fall" :style="el.style1">
						<rect
							:x="el.w"
							:y="el.y"
							:width="el.width"
							:height="el.height"
							:style="el.style2"
							:fill="el.fill"
						></rect>
					</g>
				</g>
			</g>
		</svg>
	</div>
</template>
<script>
export default {
	props: ["boom"],
	data() {
		return {
			confetti: []
		};
	},
	mounted() {
		let numberOfConfetti = 100;
		for (let i = 0; i < numberOfConfetti; i++) {
			this.confetti.push(this.generateConfetto());
		}
	},
	methods: {
		generateConfetto() {
			let colors = [
				"#00aeef",
				"#bfedff",
				"#fff",
				"#00cca0",
				"#7209b7",
				"#f72585",
				"#ff8500"
			];
			return {
				transform1: "translate(0, " + this.rand(-3000, 1500) + ")",
				transform2: "translate(" + this.rand(-1500, 3000) + ",0)",
				style1: "animation-duration:" + this.rand(1, 6) + "s",
				x: 0,
				y: 0,
				width: this.rand(7, 15),
				height: this.rand(7, 15),
				style2: "transform:scale(" + this.rand(1, 5) + ")",
				fill: colors[Math.round(this.rand(0, colors.length - 1))]
			};
		},
		rand(min, max) {
			return Math.random() * (max - min) + min;
		}
	}
};
</script>
<style scoped type="scss">
.confetti,
.confetti svg {
	display: inline-block;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	pointer-events: none;
	width: 100vw;
	height: 100vh;
	position: fixed;
	background: transparent;
}
@keyframes ld-fall {
	0% {
		transform: translate(0, 0px) rotate3d(1, 1, 1, 0deg);
	}
	100% {
		transform: translate(0, 1800px) rotate3d(1, 1, 1, 1080deg);
	}
}
.ld.ld-fall {
	animation: ld-fall 10s infinite;
	animation: ld-fall 5s infinite;
}
</style>
