<template>
	<div
		:class="{
			abstract: true,
			'is-child-of-panel': this.parentBlock.name == 'qzrstudio/panel',
		}"
	>
		<div :class="'font-size-25 color-' + pageColor" v-html="block.attrs.abstract" />
		<div
			class="abstract-background"
			:class="{
				['background-' + pageColor + '-light']: true,
			}"
		></div>
	</div>
</template>

<script>
export default {
	name: 'lazyblock_diagnosis',
	props: ['block', 'blocks', 'parentBlock'],
};
</script>

<style lang="scss" scoped>
.background {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: -1;
}
.font-size-25 {
	max-width: 750px;
}

.abstract-background {
	content: '';
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: -10%;
	right: -10%;
}

.abstract.is-child-of-panel {
	padding: 0;
	margin-bottom: 0;
}
.abstract {
	color: $aili-purple;
	padding: $spacing-1 0;
	z-index: 1;
	position: relative;
	margin-bottom: $spacing-1;
}
</style>
