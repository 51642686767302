<template>
	<div>
		<div class="background-aili-blue-light first-panel-container">
			<div class="header color-aili-blue">
				<h1>
					La tua tessera AILI
				</h1>
				<h3 v-if="user_status == 'active'" class="subtitle">
					{{ loginData.user.data ? loginData.user.data.first_name[0] : '' }}, fai parte della famiglia AILI e
					puoi accedere subito ai tanti vantaggi, come sconti, comunicazioni e accesso libero a tutti i
					contenuti del sito. La quota associativa è di € 20,00 annui.<br />
					Tutti i vantaggi della tessera sempre a portata di mano?
					<a href="https://www.associazioneaili.it/app">Scarica l'App Senza Lattosio!</a>
				</h3>
				<h3 v-if="user_status == 'pending_renewal_payment'" class="subtitle">
					{{ loginData.user.data ? loginData.user.data.first_name[0] : '' }}, per poter continuare a far parte
					della famiglia AILI ti chiediamo di rinnovare la tua tessera con la quota associativa.
				</h3>
				<h3 v-if="user_status == 'pending_subscription_payment'" class="subtitle">
					{{ loginData.user.data ? loginData.user.data.first_name[0] : '' }}, per entrare a far parte della
					famiglia AILI e accedere a tanti vantaggi, continua la registrazione con il versamento della quota
					associativa.
				</h3>

				<div v-if="user_status == 'active' && loginData.user.data.membership_next_update">
					<p class="expiring-date">
						La tua tessera scade il {{ loginData.user.data.membership_next_update[0] }}.
					</p>
				</div>
				<div v-if="user_status == 'pending_renewal_payment' && loginData.user.data.membership_next_update">
					<p class="expiring-date">
						La tua tessera è scaduta il {{ loginData.user.data.membership_next_update[0] }}.
					</p>
				</div>
			</div>
			<div class="card-container" v-if="user_status == 'active' || user_status == 'pending_renewal_payment'">
				<div class="card" :class="{ disabled: user_status == 'pending_renewal_payment' }" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['block', 'blocks', 'parentBlock'],
	mounted() {},
	methods: {},
};
</script>

<style lang="scss" scoped>
.download-button {
  display: inline-block;
  padding: 6px 15px;
  margin-right: $spacing-0;
  color: $aili-blue;
  font-size: $font-size-14;
  letter-spacing: normal;
  text-transform: uppercase;
  background-color: $aili-white;
  border: 2px solid $aili-blue;
  border-radius: 90px;
  transition: all .3s;
}
.download-button:hover {
  color: $aili-white;
  background-color: $aili-blue;
  border: 2px solid transparent;
  cursor: pointer;
}
.expiring-date {
  padding-top: $spacing-0;
}
.first-panel-container {
  width: 100%;
  @media (min-width: $desktop) {
    display: inline-flex;
  }
}
.header {
  @media (max-width: $tablet-l) {
    width: 100%;
  }
  @media (min-width: $desktop) {
    width: 60%;
    padding-right: $spacing-2;
  }
}
.card-container {
  width: 35%;

  @media (max-width: $tablet-l) {
    width: 80%;
    margin: $spacing-1 auto;
  }

  .card {
    padding-top: 66%;
    background-image: url(../../../img/aili_card.png);
    background-position: center center;
    background-size: cover;
    box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    border-radius: 15px;
    transform: rotate(10deg);

    &.disabled {
      filter: grayscale(100%);
    }

    &:hover {
      animation: shake 1s;
      animation-iteration-count: infinite;
    }
  }
}

.subtitle {
  padding-top: $spacing-0;
  padding-bottom: $spacing-0;
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(10deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(11deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(9deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(10deg);
  }
  40% {
    transform: translate(-3px, 0px) rotate(9deg);
  }
  50% {
    transform: translate(3px, 2px) rotate(10deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
</style>
