import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=c23603f0&scoped=true&"
import script from "./Form.vue?vue&type=script&lang=js&"
export * from "./Form.vue?vue&type=script&lang=js&"
import style0 from "./Form.vue?vue&type=style&index=0&id=c23603f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c23603f0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VCheckbox,VDialog,VFadeTransition,VForm,VTextField})
