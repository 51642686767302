<template>
	<div class="paypal" v-html="block.attrs.code">{{ block }}</div>
</template>

<script>
export default {
	props: ['block', 'blocks', 'parentBlock'],
};
</script>

<style lang="scss" scoped>
.paypal {
	overflow: hidden;
	display: block;
	margin: auto;
}
</style>
