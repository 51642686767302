<template>
	<div>
		<div v-for="(link, i) in parseAttribute(block.attrs.links)" :key="i" :class="'urls color-' + pageColor">
			<a
				class="url font-size-35"
				:target="link.open_blank ? '_blank' : '_self'"
				:href="link.url"
				@mouseover="hoverIndex = i"
				@mouseleave="hoverIndex = false"
				>{{ link.label }}
			</a>
			<a :href="link.url">
				<Arrow :hover="hoverIndex === i" class="arrow" :color="pageColor" type="right" />
			</a>
		</div>
	</div>
</template>

<script>
import Arrow from '@/components/ui/Arrow.vue';

export default {
	name: 'lazyblock_buttons_link',
	props: ['block'],
	components: {
		Arrow,
	},
	data() {
		return {
			hoverIndex: false,
		};
	},
};
</script>

<style lang="scss" scoped>
.arrow {
  display: inline-block;
  width: 20%;
  vertical-align: middle;
}
.core_heading {
  margin-bottom: 1rem;
}
.image {
  height: 35vw;
  background-position: top;
}
.panel {
  color: $aili-blue;
  background: $aili-white;
}

.row {
  padding: $spacing-0;
}

.urls {
  padding: $spacing-0 0;
  line-height: 1;
  border-bottom: 1px solid;
  @media (max-width: 768px) {
    padding: $spacing-0/2 0;
    line-height: 1.25;
  }
}

.url {
  display: inline-block;
  width: 80%;
  font-size: $font-size-14;
  text-decoration: none;
  vertical-align: middle;
}
</style>
