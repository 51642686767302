<template>
	<v-menu
		ref="menu"
		v-model="menu"
		bottom
		transition="scroll-y-reverse-transition"
		min-width="290px"
		:close-on-content-click="false"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				v-model="formattedDate"
				readonly
				v-bind="attrs"
				v-on="on"
				:label="field.label"
				:rules="rules || []"
			></v-text-field>
		</template>
		<v-date-picker
			@change="onSelectDate"
			ref="picker"
			:first-day-of-week="1"
			locale="it-IT"
			:min="getMinDate()"
			:max="getMaxDate()"
		></v-date-picker>
	</v-menu>
</template>
<script>
export default {
	props: {
		field: {
			type: Object,
			required: true
		},
		rules: {
			type: Array,
			required: false
		}
	},
	data() {
		return {
			menu: false
		};
	},
	watch: {
		menu(val) {
			val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
		}
	},
	computed: {
		formattedDate() {
			return this.formatDate(this.field.value);
		}
	},
	methods: {
		formatDate(date) {
			if (!date) return null;
			if (date.includes("-")) {
				const [year, month, day] = date.split("-");
				return `${day}/${month}/${year}`;
			} else {
				return date;
			}
		},
		getMinDate() {
			return "1920-01-01";
		},
		getMaxDate() {
			return new Date().toISOString().substr(0, 10);
		},
		onSelectDate(value) {
			this.$refs.menu.save(value);
			this.field.value = value;
			this.$emit("onDateChange", value);
		}
	}
};
</script>
