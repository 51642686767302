<template>
	<div v-if="loginData.user.data">
		<div class="edit-password">
			<h2 data-v-6c811bc2="" class="color-aili-black font-size-25">
				Modifica password
			</h2>
			<v-fade-transition mode="out-in">
				<v-form class="form" ref="form" v-model="valid_password">
					<v-text-field
						class="field"
						type="password"
						v-model="password"
						label="Password"
						:rules="passswordCheck"
					></v-text-field>
					<v-text-field
						class="field"
						type="password"
						v-model="password_repeat"
						label="Ripeti password"
						:rules="passwordConfirmCheck"
					></v-text-field>

					<Button
						class="save-password"
						:loading="loading"
						:disabled="!valid_password"
						color="aili-blue"
						@click="onChangePasswordClick"
						value="Salva"
					/>
				</v-form>
			</v-fade-transition>

			<div class="error-message" v-if="error_message">
				{{ error_message }}
			</div>

			<v-dialog v-model="confirmedPasswordChange" width="500">
				<v-fade-transition mode="out-in">
					<div 
						class="confirm-change-password color-aili-white"
						:class="`background-${pageColor}`"
						>
						<SuccessCheckbox />
						Password modificata con successo!<br>
						Accedi nuovamente per piacere.
					</div>
				</v-fade-transition>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import { login } from "./authentication/Login";
import SuccessCheckbox from "@/components/ui/SuccessCheckbox.vue";


export default {
	mixins: [login],
	components: {
		SuccessCheckbox
	},
	data() {
		return {
			password: null,
			password_repeat: null,
			valid_password: false,
			confirmedPasswordChange: false,
			loading: false,
			error_message: null,
			passswordCheck: [
				v =>
					(v && v.length >= 8) ||
					"La password deve essere di almeno 8 caratteri"
			],
			passwordConfirmCheck: [
				() =>
					this.password === this.password_repeat ||
					"Le password devono corrispondere",
				v => !!v || "Campo obbligatorio"
			]
		};
	},
	methods: {
		async onChangePasswordClick() {
			this.loading = true;
			this.error_message = null;

			let response = await this.$api.post("/qzr-data/save-fields", {
				password: this.password,
				password_repeat: this.password,
				scope: JSON.stringify("password"),
				type: "base",
				role: "member"
			});

			if (response.data.result == "error") {
				this.error_message = Object.values(
					response.data.data.errors
				)[0][0];
				this.loading = false;
			} else {
				this.confirmedPasswordChange = true;

				setTimeout(
					function() {
						this.logout();
						this.goTo("Home", { login: true });
					}.bind(this),
					5000
				);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.confirm-change-password {
	.confirm-success {
		margin: auto;
	}
	padding: $spacing-1;
	position: relative;
	text-align: center;
	
	align-items: center;
	display: flex;
	justify-content: center;
	height: 400px;
	transition: all 0.5s;
	width: 100%;
	overflow-y: hidden;
}

.edit-password {
	margin-top: $spacing-1;
}

.field {
	width: 50%;
	padding-right: 50px;
	margin-bottom: $spacing-0;

	@media (max-width: $tablet-l) {
		width: 100%;
		padding-right: 0;
	}
}
.form {
	.save-password {
		min-width: 200px;
	}
	width: 100%;
	display: inline-flex;
	align-items: flex-start;
	@media (max-width: $tablet-l) {
		display: inline-block;
	}
}
.loading {
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.6);

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
.error-message {
	background-color: #ff0087;
	color: white;
	padding: 25px;
}
</style>
