<template>
	<div
		class="private-content"
		:class="{
			'private-content-full-width': parentBlock,
			'private-content-gradient':
				!loginData.user ||
				(loginData.user.data.qzr_status && loginData.user.data.qzr_status.indexOf('active') === -1),
		}"
	>
		<div
			v-if="
				!loginData.user ||
					(loginData.user.data.qzr_status && loginData.user.data.qzr_status.indexOf('active') === -1)
			"
		>
			<div :class="'form background-' + pageColor + '-light'">
				<div class="icon-access">
					<svg
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						x="0px"
						y="0px"
						viewBox="0 0 36 36"
						style="enable-background:new 0 0 36 36;"
						xml:space="preserve"
					>
						<g id="Livello_6">
							<path
								fill="white"
								d="M31,12.9c-0.9-0.9-2-1.6-3.2-2V6.3C27.8,2.8,25,0,21.5,0h-6.7c-3.5,0-6.4,2.8-6.4,6.3v4.6
			c-1.3,0.4-2.4,1.1-3.4,2v0c-3.3,3.2-3.3,8.4,0,11.6L15.6,35c1.3,1.3,3.4,1.3,4.7,0L31,24.6C34.3,21.3,34.3,16.1,31,12.9z
			M20.7,28.5h-5l0.9-4.7c-1.1-0.6-1.8-1.6-1.8-2.9c0-1.8,1.5-3.3,3.4-3.3c1.9,0,3.4,1.5,3.4,3.3c0,1.2-0.7,2.3-1.7,2.9L20.7,28.5z
			M19.2,12.9L18,14.1l-1.2-1.2c-1.6-1.6-3.7-2.4-5.8-2.4V6.3c0-2,1.7-3.6,3.7-3.6h6.7c2,0,3.7,1.6,3.7,3.6v4.2
			C23,10.5,20.8,11.3,19.2,12.9z"
							/>
						</g>
					</svg>
				</div>
				<h2 v-if="paywall" :class="'font-size-25  color-' + pageColor" v-html="paywall.title.rendered"></h2>
				<br class="clear" />
				<div class="form-content">
					<div class="login-form">
						<LoginForm :color="backgroundColor" />
					</div>
					<div :class="'login-render-blocks background-' + pageColor">
						<RenderBlocks :blocks="paywall.blocks" />
						<Button
							:inverted="true"
							:color="pageColor"
							:value="register.post_title"
							@click="goTo('Page', { slug: register.post_name })"
						/>
					</div>
					<br class="clear" />
				</div>
			</div>
		</div>
		<div
			v-else
			class="user-active"
			:class="{
				['color-' + pageColor]: true,
			}"
		>
			<div>
				<v-icon class="success-icon" tiny :class="'color-' + pageColor">
					mdi-check-circle
				</v-icon>
				Questo contenuto è accessibile perché sei associato ad AILI
			</div>
		</div>
	</div>
</template>

<script>
import LoginForm from '@/components/ui/LoginForm.vue';

export default {
	name: 'lazyblock_private_content',
	props: ['block', 'blocks', 'parentBlock'],
	components: {
		LoginForm,
		// Wave,
		RenderBlocks: () => import('@/components/blocks/RenderBlocks.vue'),
	},
	watch: {
		pageColor(newValue) {
			switch (newValue) {
				case 'aili-green':
					this.backgroundColorLight = '#ccf8ee';
					this.backgroundColor = '#00cca0';
					break;
				case 'aili-yellow':
					this.backgroundColorLight = '#ffe7cc';
					this.backgroundColor = '#ff8500';
					break;
				case 'aili-purple':
					this.backgroundColorLight = '#e3cdf1';
					this.backgroundColor = '#7209b7';
					break;
				case 'aili-pink':
					this.backgroundColorLight = '#fed3e7';
					this.backgroundColor = '#f72585';
					break;
				default:
					this.backgroundColorLight = '#bfedff';
					this.backgroundColor = '#00aeef';
			}
		},
	},
	data() {
		return {
			dialog: true,
			loading_donation: false,
			paywall: false,
			username: '',
			password: '',
			showSubscribeForm: false,
			register: [],
			backgroundColorLight: null,
			backgroundColor: null,
		};
	},
	async mounted() {
		let response_register = await this.$api.get('/qzr-data/qzr_fp_pages/qzr_um_registration_page/');
		this.register = response_register.data[0];

		if (this.block.attrs.paywall) {
			let paywall_id = JSON.parse(this.block.attrs.paywall).posts[0];

			let response = await this.$api.get('/wp/v2/paywall/' + paywall_id);
			this.paywall = response.data;
		}
	},
};
</script>

<style lang="scss" scoped>
#app {
  .private-content {
    position: relative;
  }

  .private-content-full-width {
    margin: 0 calc(-#{$spacing-0} - 2.5%);

    @media (max-width: $mobile-m) {
      margin: 0 calc(-#{$spacing-0/2} - 2.5%);
    }
  }

  .private-content-gradient:before {
    content: "";
    position: absolute;
    top: -100px;
    right: 0;
    left: 0;
    height: 100px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 10%, transparent);
  }

  .form:before {
    position: absolute;
    top: -99px;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100px;
    background: linear-gradient(to bottom, rgba(255, 0, 0, 0), #fff);
  }
  .v-icon {
    transition: none !important;
  }

  .form {
    position: relative;
    padding: $spacing-1;
    @media (max-width: $tablet-l) {
      padding: $spacing-0;
    }
    @media (max-width: $mobile-m) {
      padding: $spacing-0 $spacing-0/2;
    }
    .icon-access {
      float: left;
      width: 48px;
      height: 48px;
      padding: 0;
    }

    h2 {
      position: relative;
      float: left;
      width: 100%;
      max-width: 500px;
      margin-bottom: $spacing-0;
      margin-left: $spacing-0;
      @media (max-width: $tablet-l) {
        width: calc(100% - 80px);
      }
    }
  }

  .paragraph {
    padding: $spacing-1 0;
  }
  .login {
    @media (max-width: $tablet-l) {
      width: 100%;
    }
  }
  .form-content {
    display: inline-flex;
    width: 100%;
    background: #fff;

    .clear {
      @media (max-width: $tablet-l) {
        display: none;
      }
    }
    @media (max-width: $tablet-l) {
      flex-direction: column-reverse;
      flex-wrap: wrap;
    }
    .login-form {
      width: 50%;
      padding: $spacing-1;
      color: black;
      text-align: center;
      @media (max-width: $tablet-l) {
        width: 100%;
      }

      @media (max-width: $mobile-m) {
        padding: $spacing-1 $spacing-0;
      }
    }

    .login-render-blocks {
      width: 50%;
      padding: $spacing-1;
      color: #fff;
      @media (max-width: $tablet-l) {
        width: 100%;
        padding: $spacing-0;
      }
    }
  }
  .user-active {
    position: -webkit-sticky;
    position:         sticky;
    position: relative;
    bottom: 25px;
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding-bottom: 6px;
    padding-left: 85px;
    margin: 25px 0 25px;
    font-size: 14px;
    text-transform: uppercase;
    border-bottom: 2px dotted;
    .success-icon {
      position: absolute;
      left: 50px;
    }
    @media (max-width: $tablet-m) {
      width: 100%;
      padding-right: 25px;
      padding-left: 55px;
      margin: 25px 0;
      .success-icon {
        left: 25px;
      }
    }
  }
}
</style>
