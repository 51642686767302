<template>
	<div>
		<div v-if="user_status == 'active'">
			<h3 :class="'color-' + pageColor">{{ block.attrs.title }}</h3>
			<div>
				<v-slide-y-reverse-transition mode="out-in">
					<Donation
						v-if="!donation_success"
						@paymentStarted="paymentStarted"
						@paymentCompleted="paymentCompleted"
						buttonColor="aili-blue"
						paymentType="donation"
						:billingInfo="required_billing_info"
						:buttonText="block.attrs['button-label']"
						:donation_rules="donation_rules"
						:fullWidth="false"
						:infoToSaveInStripe="info"
					>
					</Donation>
					<div v-else class="success-donation color-aili-blue">
						<SuccessCheckbox color="#00aeef" />
						La tua donazione è andata a buon fine. Grazie per il tuo supporto!
					</div>
				</v-slide-y-reverse-transition>
				<div class="loadingWrapper">
					<Loading v-if="loading_donation" />
				</div>
			</div>
		</div>

		<div v-else>
			<!-- <LazyblockSubscription /> -->

			<div class="logged-out">
				<Button
					class="login button"
					color="aili-blue"
					:value="
						user_status == 'pending_renewal_payment'
							? 'Rinnova la tua tessera'
							: 'Continua la tua registrazione'
					"
					@click="goTo('Page', { slug: 'iscriviti' })"
				/>
				<v-tooltip bottom v-model="showTooltip">
					<template v-slot:activator="{ on, attrs }">
						<v-icon
							@click="showTooltip = !showTooltip"
							x-small
							color="aili-blue"
							class="outlined"
							v-bind="attrs"
							v-on="on"
						>
							mdi-help
						</v-icon>
					</template>
					<span>{{ block.attrs.tooltip }}</span>
				</v-tooltip>
			</div>
		</div>
	</div>
</template>

<script>
import Donation from '@/components/ui/donation/Donation.vue';
import Loading from '@/components/ui/Loading.vue';
import SuccessCheckbox from '@/components/ui/SuccessCheckbox.vue';
//import LazyblockSubscription from '@/components/blocks/lazyblocks/lazyblock_subscription.vue';
export default {
	props: ['block', 'blocks', 'parentBlock'],
	components: {
		Donation,
		Loading,
		SuccessCheckbox,
		//LazyblockSubscription
	},
	data() {
		return {
			donation_rules: [(v) => !!v || 'Inserire un importo'],
			info: null,
			showTooltip: false,
			loading_donation: false,
			donation_success: false,
		};
	},
	computed: {
		required_billing_info() {
			return this.loginData && this.loginData.user && this.loginData.user.data
				? {
						other_data: {
							first_name: this.loginData.user.data.first_name[0],
							last_name: this.loginData.user.data.last_name[0],
						},
						billing_details: {
							address: {
								country: 'IT',
								city: this.loginData.user.data.municipality[0],
								postal_code: this.loginData.user.data.cap[0],
								state: '',
								line1: this.loginData.user.data.address[0],
								line2: '',
							},
						},
						vat_fields: {
							fiscal_code: this.loginData.user.data.fiscal_code[0],
							vat_number: '',
							pecsdi: '',
							company: '',
						},
				  }
				: null;
		},
	},
	methods: {
		paymentStarted() {
			this.loading_donation = true;
		},
		paymentCompleted(payment_intent_id) {
			let donation_status = setInterval(async () => {
				let response = await this.$api.post('/qzr-data/get_payment_status', {
					payment_intent_id: payment_intent_id,
				});
				if (response.data === 'success') {
					this.loading_donation = false;
					this.donation_success = true;
					clearInterval(donation_status);
				}
			}, 1000);
		},
	},
};
</script>

<style lang="scss" scoped>
.loadingWrapper {
	position: relative;
	width: 100%;
}
::v-deep #card {
	padding: 10px 20px 11px;
	background-color: $aili-blue-light;
	border-radius: 20px;
	width: 100%;
	input {
		color: white;
	}
}
::v-deep .card-row {
	margin: $spacing-0 0;
}
::v-deep .donation-button {
	.v-btn__content {
		color: $aili-white;
	}
	letter-spacing: normal;
	border-radius: 90px;
	transition: all 0.3s;
	font-size: $font-size-14;
}
.logged-out {
	margin-top: $spacing-0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.success-donation {
	align-items: center;
	display: flex;
	justify-content: center;
	height: 100px;
	overflow-y: hidden;
	transition: all 0.5s;
	width: 100%;
}
.v-icon.outlined {
	border: 1px solid $aili-blue;
	border-radius: 50%;
	height: 25px;
	width: 25px;
}
</style>
