<template>
	<ul>
		<li v-html="element" v-for="(element, i) in block.attrs.elements" :key="i"></li>
		<RenderBlocks v-if="block.innerBlocks" :blocks="block.innerBlocks" :parentBlock="block" />
	</ul>
</template>

<style lang="scss" scoped>
	ul {
		max-width: 700px;
		margin-bottom: $spacing-0;
		li {
			margin-bottom: 5px;
			font-size: 18px;
		}
	}
</style>

<script>
	export default {
		name: 'core_image',
		props: ['block'],
		components: {
			RenderBlocks: () => import('@/components/blocks/RenderBlocks.vue')
		}
	};
</script>
