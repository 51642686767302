var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"slider"},[(
			_vm.$vuetify.breakpoint.width < 768 ||
				(_vm.$vuetify.breakpoint.width < 1024 && _vm.updatedCards.length > 2) ||
				(_vm.updatedCards.length > 3 &&
					(!_vm.block.attrs.className ||
						_vm.block.attrs.className !== 'carousel-4-cards' ||
						(_vm.block.attrs.className == 'carousel-4-cards' && _vm.updatedCards.length > 4)))
		)?_c('div',{staticClass:"buttons"},[_c('div',{on:{"click":_vm.prevPage}},[_c('Arrow',{attrs:{"type":"left","color":_vm.updatedColor}})],1),_c('div',{on:{"click":_vm.nextPage}},[_c('Arrow',{attrs:{"type":"right","color":_vm.updatedColor},on:{"click":_vm.prevPage}})],1)]):_vm._e(),(_vm.showSwiper)?_c('swiper',{ref:"swiperCardSlideshow",class:'swiper-card-slideshow ' + _vm.updatedColor + '-swiper',attrs:{"options":_vm.swiperOption},on:{"slideChange":_vm.onSlideChange}},[_vm._l((_vm.updatedCards),function(card,i){
		var _obj;
return _c('swiper-slide',{key:i},[_c('v-card',{staticClass:"card content-shadow",attrs:{"tile":""}},[(card.image && card.image.url)?_c('img',{staticClass:"small-img",attrs:{"src":card.image.url}}):_vm._e(),(card.title)?_c('h3',{staticClass:"cardTitle",class:( _obj = {
						'font-size-25': !_vm.isSubscribe,
						'font-size-20': _vm.isSubscribe
					}, _obj['color-' + _vm.updatedColor] = true, _obj ),domProps:{"innerHTML":_vm._s(card.title)}}):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(card.description)}}),_c('p',{staticClass:"font-size-14",domProps:{"innerHTML":_vm._s(card['smaller-description'])}}),(card.button)?_c('div',[_c('Button',{attrs:{"color":_vm.updatedColor,"value":card.label ? card.label : 'Scopri di più',"target":card.open_blank ? '_blank' : '_self'},on:{"click":function($event){return _vm.changeUrl(card.button)}}})],1):_vm._e()])],1)}),(
				_vm.$vuetify.breakpoint.width < 768 ||
					(_vm.$vuetify.breakpoint.width < 1024 && _vm.updatedCards.length > 2) ||
					(_vm.updatedCards.length > 3 &&
						(!_vm.block.attrs.className ||
							_vm.block.attrs.className !== 'carousel-4-cards' ||
							(_vm.block.attrs.className == 'carousel-4-cards' && _vm.updatedCards.length > 4)))
			)?_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"}):_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }