<template>
	<div>
		<v-form class="form" ref="form" v-model="valid">
			<!-- Account -->
			<h2 class="color-aili-black font-size-25">
				{{ values ? 'Modifica account' : 'Registrazione Account' }}
			</h2>
			<!-- <v-text-field
				class="field"
				v-model="fields.username"
				:rules="notEmpty"
				label="Username*"
				:disabled="values"
				required
			></v-text-field> -->
			<v-text-field
				class="field"
				v-model="fields.email"
				:rules="notEmpty"
				label="E-mail*"
				required
				:disabled="values"
				type="email"
			></v-text-field>
			<div class="field filler" />

			<v-text-field
				class="field"
				v-model="fields.password"
				:rules="notEmpty"
				label="Password*"
				required
				type="password"
				v-if="!values"
			></v-text-field>
			<v-text-field
				class="field"
				v-model="fields.password_repeat"
				:rules="notEmpty"
				label="Conferma password*"
				required
				type="password"
				v-if="!values"
			></v-text-field>

			<!-- Dati personali -->
			<h2 class="color-aili-black font-size-25">Dati personali</h2>

			<v-text-field class="field" v-model="fields.first_name" :rules="notEmpty" label="Nome*"></v-text-field>

			<v-text-field class="field" v-model="fields.last_name" :rules="notEmpty" label="Cognome*"></v-text-field>

			<div class="field">
				<DatePicker
					@onDateChange="onDateOfBirthChange"
					:field="{
						label: 'Data di nascita*',
						value: fields.date_of_birth,
					}"
					:rules="notEmpty"
				/>
			</div>

			<v-autocomplete
				class="field"
				v-model="fields.place_of_birth"
				hide-no-data
				hide-selected
				item-text="name"
				item-value="name"
				label="Nato a*"
				:rules="notEmpty"
				:filter="filterMunicipalitiesByName"
				:items="municipalities"
				v-if="municipalities"
			></v-autocomplete>

			<v-autocomplete
				class="field"
				v-model="fields.municipality"
				hide-no-data
				hide-selected
				item-text="name"
				item-value="name"
				label="Comune di residenza*"
				:rules="notEmpty"
				:filter="filterMunicipalitiesByName"
				:items="municipalities"
				v-if="municipalities"
			></v-autocomplete>

			<v-text-field
				class="field address"
				v-model="fields.address"
				:rules="notEmpty"
				label="Indirizzo di residenza*"
			></v-text-field>

			<v-text-field
				class="field address-number"
				v-model="fields.address_number"
				:rules="notEmpty"
				label="Civico*"
			></v-text-field>

			<v-text-field
				class="field"
				v-model.number="fields.cap"
				:rules="notEmpty"
				label="CAP*"
				type="number"
			></v-text-field>

			<v-autocomplete
				class="field"
				v-model="fields.state"
				hide-no-data
				hide-selected
				item-text="name"
				item-value="id"
				label="Provincia*"
				:filter="filterStatesByName"
				:items="states"
				:rules="notEmpty"
				v-if="states"
			></v-autocomplete>

			<v-text-field
				class="field"
				v-model="fields.fiscal_code"
				:rules="notEmpty"
				label="Codice Fiscale*"
			></v-text-field>

			<v-text-field
				class="field"
				v-model="fields.phone_number"
				:rules="notEmpty"
				label="Cellulare*"
				type="tel"
			></v-text-field>
			<v-checkbox
				v-model="fields.status_domicile"
				color="black"
				label="Il domicilio è diverso dalla residenza"
				class="field"
			></v-checkbox>

			<!-- Domicilio -->
			<div
				:class="{
					domicile: true,
					active: fields.status_domicile,
				}"
			>
				<h2 class="color-aili-black font-size-25">Domicilio</h2>
				<v-autocomplete
					class="field"
					v-model="fields.municipality_domicile"
					hide-no-data
					hide-selected
					item-text="name"
					item-value="name"
					label="Comune*"
					:filter="filterMunicipalitiesByName"
					:items="municipalities"
					:rules="domicileFieldNotEmpty"
					v-if="municipalities"
				></v-autocomplete>
				<v-text-field
					class="field address-number"
					v-model="fields.address_domicile"
					label="Indirizzo di domicilio*"
					:rules="domicileFieldNotEmpty"
				></v-text-field>
				<v-text-field
					class="field address-number-domicile"
					v-model="fields.address_number_domicile"
					label="Civico domicilio*"
					:rules="domicileFieldNotEmpty"
				></v-text-field>
				<v-text-field
					class="field"
					v-model="fields.cap_domicile"
					label="CAP*"
					type="number"
					:rules="domicileFieldNotEmpty"
				></v-text-field>

				<v-autocomplete
					class="field"
					v-model="fields.state_domicile"
					hide-no-data
					hide-selected
					item-text="name"
					item-value="id"
					label="Provincia*"
					:filter="filterStatesByName"
					:items="states"
					:rules="domicileFieldNotEmpty"
					v-if="states"
				></v-autocomplete>
				<div class="field filler" />
			</div>

			<!-- Genitoriale -->
			<div
				:class="{
					parents: true,
					active: fields.guardianship_status,
				}"
			>
				<h2 class="color-aili-black font-size-25">
					Dati del responsabile genitoriale
				</h2>
				<v-text-field
					class="field"
					v-model="fields.guardianship_first_name"
					label="Nome genitore*"
					:rules="parentsFieldNotEmpty"
				></v-text-field>
				<v-text-field
					class="field"
					v-model="fields.guardianship_last_name"
					label="Cognome genitore*"
					:rules="parentsFieldNotEmpty"
				></v-text-field>
				<div class="field">
					<DatePicker
						@onDateChange="onDateOfBirthGuardianshipChange"
						:field="{
							label: 'Data di nascita*',
							value: fields.guardianship_birthday,
						}"
						:rules="parentsFieldNotEmpty"
					/>
				</div>
			</div>
		</v-form>
		<div class="submit-container">
			<!-- Checkboxes -->
			<div
				v-if="!values"
				class="checkboxes"
				:class="{
					active: !values,
				}"
			>
				<v-checkbox
					v-model="fields.newsletter"
					color="black"
					label="Desidero essere iscritto alla newsletter"
					class="field-checkbox"
				></v-checkbox>
				<v-checkbox
					v-if="!values"
					v-model="fields.terms"
					color="black"
					label="Accetto i termini e le condizioni del servizio"
					class="field-checkbox"
					:rules="[(v) => !!v || 'Devi accettare i termini e le condizioni del servizio']"
				></v-checkbox>
			</div>
			<div class="subscribe-button-container">
				<Button
					:loading="loading"
					:disabled="!valid"
					color="aili-blue"
					@click="$emit('onSubscribeClick', fields)"
					:value="values ? 'Salva' : 'Procedi'"
				/>
			</div>
			<v-dialog v-model="confirmedProfileChange" width="500">
				<v-fade-transition mode="out-in">
					<div class="confirm-change-profile white text-center row-padding">
						Profilo modificato con successo
					</div>
				</v-fade-transition>
			</v-dialog>
		</div>
	</div>
</template>
<script>
import DatePicker from '@/components/ui/DatePicker.vue';

export default {
	components: { DatePicker },
	props: ['values', 'loading', 'confirmedProfileChange'],
	data() {
		return {
			valid: false,
			fields: {
				address: null,
				address_domicile: null,
				address_number: null,
				address_number_domicile: null,
				cap: null,
				cap_domicile: null,
				date_of_birth: null,
				email: null,
				first_name: null,
				fiscal_code: null,
				guardianship_birthday: null,
				guardianship_first_name: null,
				guardianship_last_name: null,
				guardianship_status: false,
				last_name: null,
				municipality: null,
				municipality_domicile: null,
				newsletter: false,
				password: null,
				password_repeat: null,
				phone_number: null,
				place_of_birth: null,
				state: null,
				state_domicile: null,
				status_domicile: false,
				terms: false,
				username: null,
			},
			notEmpty: [(v) => !!v || 'Campo obbligatorio'],
			domicileFieldNotEmpty: [(v) => !this.fields.status_domicile || !!v || 'Campo obbligatorio'],
			parentsFieldNotEmpty: [(v) => !this.fields.guardianship_status || !!v || 'Campo obbligatorio'],
		};
	},
	async mounted() {
		await this.getMunicipalities();
		await this.getStates();
		// Prendo il props, lo scorro e refillo tutti i campi compilati
		for (let property in this.values) {
			switch (property) {
				case 'nickname':
					this.fields['username'] = this.values[property];
					break;
				case 'user_email':
					this.fields['email'] = this.values[property];
					break;
				default:
					this.fields[property] =
						this.values[property].length == 1 ? this.values[property][0] : this.values[property];
			}
		}
	},
	methods: {
		onDateOfBirthChange(val) {
			this.fields.date_of_birth = val;
			if (this.getAge(val) >= 18) {
				this.fields.guardianship_status = false;
			} else {
				this.fields.guardianship_status = true;
			}
		},
		onDateOfBirthGuardianshipChange(val) {
			this.fields.guardianship_birthday = val;
		},
		getAge(DOB) {
			var today = new Date();
			var birthDate = new Date(DOB);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		},
	},
};
</script>

<style lang="scss" scoped>
.hide-field {
  display: none;
}
.subscription-form {
  margin-top: $spacing-1;

  h2 {
    width: 100%;
    margin-top: $spacing-1;
    margin-bottom: $spacing-0;
    @media (max-width: $tablet-l) {
    }
  }

  .form,
  .domicile,
  .parents {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .checkboxes {
    align-items: center;
    width: 100%;
    padding: 10px 0;
    @media (max-width: $tablet-l) {
      margin: 25px 0;
      margin-top: 0;
      margin-bottom: 50px;
      .v-input {
        margin-top: 25px;
      }
      .v-input:first-child {
        margin-top: 0;
      }
    }
  }

  .field {
    width: 50%;
    padding-right: 50px;
    margin-bottom: $spacing-0;

    &.address,
    &.address-domicile {
      width: 35%;
    }

    &.address-number,
    &.address-number-domicile {
      width: 15%;
    }

    @media (max-width: $tablet-l) {
      width: 100%;
      padding-right: 0;
      margin-bottom: 0;

      &.address,
      &.address-domicile {
        width: 100%;
      }

      &.address-number,
      &.address-number-domicile {
        width: 100%;
      }

      &.filler {
        display: none;
      }
    }
  }

  .field-checkbox {
    width: 100%;
    height: 40px;
    margin: 0;
  }

  .domicile,
  .parents {
    overflow: hidden;
    max-height: 0;
    transition: .3s all;
    opacity: 0;
  }
  .domicile.active,
  .parents.active {
    overflow: hidden;
    max-height: 1000px;
    margin: $spacing-0 0;
    opacity: 1;
  }
}
.submit-container {
  display: inline-flex;
  align-content: center;
  width: 100%;

  @media (max-width: $mobile-m) {
    display: block;
  }
}
.subscribe-button-container {
  display: flex;
  align-items: center;
  @media (max-width: $mobile-m) {
    margin-top: $spacing-0;
  }
}

.subscribe-button {
  display: inline-block;
  padding: 6px 15px;
  margin-right: $spacing-0;
  color: #fff;
  font-size: $font-size-14;
  letter-spacing: normal;
  text-transform: uppercase;
  border: 2px solid #fff;
  border-radius: 90px;
}

.confirm-change-profile {
  padding: 25px;
}
</style>
