<template>
	<v-container fluid>
		<RenderBlocks :blocks="block.innerBlocks" :parentBlock="block" />
	</v-container>
</template>

<script>
export default {
	name: "lazyblock_us_chefs",
	props: ["block", "blocks", "parentBlock"],
	components: {},
	data() {
		return {
			posts: null
		};
	}
};
</script>

<style lang="scss" scoped>
.row {
	padding: $spacing-0;
}
</style>
