<template>
	<div :class="'block-quote font-size-18 color-' + pageColor + '-light'">
		<div
			v-if="block.attrs.quote && block.attrs.quote.trim().length > 0"
			:class="'quote color-' + pageColor"
			v-html="block.attrs.quote"
		></div>
		<div v-if="block.attrs.cite && block.attrs.cite.trim().length > 0" :class="'citation color-' + pageColor">
			{{ block.attrs.cite }}
		</div>
		<RenderBlocks v-if="block.innerBlocks" :blocks="block.innerBlocks" :parentBlock="block" />
	</div>
</template>

<style lang="scss" scoped></style>

<script>
	export default {
		name: 'core_quote',
		props: ['block'],
		components: {
			RenderBlocks: () => import('@/components/blocks/RenderBlocks.vue'),
		},
	};
</script>

<style lang="scss" scoped>
	.block-quote {
		max-width: 650px;
		padding: $spacing-1;
		padding-right: 0;
		border-left: 10px solid;
		margin: $spacing-1 0;
	}
	.citation {
		opacity: 0.55;
		padding-top: $spacing-0;
	}
</style>
