<template>
	<v-container class="slider">
		<div
			class="buttons"
			v-if="
				$vuetify.breakpoint.width < 768 ||
					($vuetify.breakpoint.width < 1024 && updatedCards.length > 2) ||
					(updatedCards.length > 3 &&
						(!block.attrs.className ||
							block.attrs.className !== 'carousel-4-cards' ||
							(block.attrs.className == 'carousel-4-cards' && updatedCards.length > 4)))
			"
		>
			<div @click="prevPage">
				<Arrow type="left" :color="updatedColor" />
			</div>
			<div @click="nextPage">
				<Arrow @click="prevPage" type="right" :color="updatedColor" />
			</div>
		</div>
		<swiper
			v-if="showSwiper"
			ref="swiperCardSlideshow"
			:class="'swiper-card-slideshow ' + updatedColor + '-swiper'"
			:options="swiperOption"
			@slideChange="onSlideChange"
		>
			<swiper-slide v-for="(card, i) in updatedCards" :key="i">
				<v-card tile class="card content-shadow">
					<img class="small-img" v-if="card.image && card.image.url" :src="card.image.url" />

					<h3
						class="cardTitle"
						:class="{
							'font-size-25': !isSubscribe,
							'font-size-20': isSubscribe,
							['color-' + updatedColor]: true,
						}"
						v-if="card.title"
						v-html="card.title"
					></h3>
					<p v-html="card.description" />
					<p class="font-size-14" v-html="card['smaller-description']"></p>
					<div v-if="card.button">
						<Button
							:color="updatedColor"
							:value="card.label ? card.label : 'Scopri di più'"
							:target="card.open_blank ? '_blank' : '_self'"
							@click="changeUrl(card.button)"
						/>
					</div>
				</v-card>
			</swiper-slide>
			<div
				v-if="
					$vuetify.breakpoint.width < 768 ||
						($vuetify.breakpoint.width < 1024 && updatedCards.length > 2) ||
						(updatedCards.length > 3 &&
							(!block.attrs.className ||
								block.attrs.className !== 'carousel-4-cards' ||
								(block.attrs.className == 'carousel-4-cards' && updatedCards.length > 4)))
				"
				class="swiper-pagination"
				slot="pagination"
			></div>
		</swiper>
	</v-container>
</template>

<script>
import Arrow from '@/components/ui/Arrow.vue';
export default {
	props: ['block', 'blocks', 'parentBlock'],
	components: {
		Arrow,
	},
	data() {
		return {
			currentIndex: 0,
			cards: [],
			color: null,
			isSubscribe: false,
			showSwiper: false,
			swiperOption: {
				slidesPerView: 1,
				//spaceBetween: 40,
				centeredSlides: false,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				breakpoints: {
					1280: {
						slidesPerView: 3,
						//spaceBetween: 40
					},
					1024: {
						slidesPerView: 3,
						//spaceBetween: 40
					},
					768: {
						slidesPerView: 2,
						//spaceBetween: 20
					},
				},
			},
		};
	},

	computed: {
		swiper() {
			return this.$refs.swiperCardSlideshow.$swiper;
		},
		updatedCards() {
			let cards = decodeURI(this.block.attrs.cards);
			return JSON.parse('[' + cards + ']')[0];
		},
		updatedColor() {
			let result = this.block.attrs.color
				? this.block.attrs.color
				: this.pageColor
				? this.pageColor
				: 'aili-blue';
			return result;
		},
	},
	mounted() {
		if (this.$route.path.indexOf('iscriviti') > 0 || this.$route.path.indexOf('tessera')) {
			this.isSubscribe = true;
		}
		if (this.block.attrs.className && this.block.attrs.className == 'carousel-4-cards') {
			this.swiperOption.breakpoints['1280'].slidesPerView = 4;
		}
		this.showSwiper = true;
	},

	methods: {
		onSlideChange() {
			this.currentIndex = this.swiper.activeIndex;
		},
		changeUrl(url) {
			window.location.href = url;
		},
		onPaginationClick(index) {
			this.swiper.slideTo(index);
		},
		nextPage() {
			this.swiper.slideNext();
		},
		prevPage() {
			this.swiper.slidePrev();
		},
	},
};
</script>

<style lang="scss" scoped>
.swiper-card-slideshow {
	right: 0;
	text-align: left;
	margin-left: 100px;
	padding: 50px 0;
	width: calc(120% - 70px);

	@media (max-width: 1600px) {
		width: calc(108%);
	}

	@media (max-width: 1500px) {
		width: calc(98%);
	}

	@media (max-width: 1280px) {
		width: calc(90vw - 85px);
	}
	@media (max-width: $tablet-l) {
		/* margin-left: -$spacing-0/2;
		width: 95vw; */
		padding: 25px 0;
		margin-left: -19px;
		width: 90vw;
	}
	@media (max-width: $mobile-m) {
		padding: 25px 0;
		margin-left: -25px;
		width: calc(100% + 50px);
	}
}

#app {
	.cardTitle {
		margin-bottom: 25px;
	}
	.card {
		padding: $spacing-0;
		position: relative;
		overflow: hidden;
		margin: 0 $spacing-0;

		@media (max-width: $tablet-m) {
			margin: 0 $spacing-0;
		}

		img {
			margin: -25px;
			width: calc(100% + 50px);
			margin-bottom: 25px;
			object-fit: contain;
		}
	}
	.buttons {
		width: 50px;
		top: 50px;
		position: absolute;
		@media (max-width: $tablet-l) {
			display: none;
		}
	}
}

@media (max-width: $tablet-m) {
	.swiper-pagination {
		bottom: 0;
	}
}

/* .small-img {
	margin-left: auto;
	margin-right: auto;
	width: 50%;
} */

.slider {
	position: relative;
}
</style>

<style lang="scss">
//Class inside swiper need to be declared outside of scoped
.aili-blue-swiper {
	.swiper-pagination-bullet-active {
		background-color: $aili-blue;
	}
}
.aili-green-swiper {
	.swiper-pagination-bullet-active {
		background-color: $aili-green;
	}
}
.aili-yellow-swiper {
	.swiper-pagination-bullet-active {
		background-color: $aili-yellow;
	}
}
.aili-pink-swiper {
	.swiper-pagination-bullet-pink {
		background-color: $aili-blue;
	}
}
.aili-purple-swiper {
	.swiper-pagination-bullet-purple {
		background-color: $aili-purple;
	}
}
</style>
