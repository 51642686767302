<template>
	<div class="gallery">
		<swiper class="swipe" ref="swiperGallery" :options="swiperOption" @slideChange="onSlideChange">
			<swiper-slide v-for="(image, i) in block.attrs.images" :key="i" class="slide" id="element-near-top">
				<img :src="image.url" />
				<div class="caption">{{ image.caption }}</div>
			</swiper-slide>
		</swiper>
	</div>
</template>

<style lang="scss" scoped>
img {
	max-width: 100%;
	margin-top: $spacing-0;
}
</style>

<script>
export default {
	name: 'core_gallery',
	props: ['block'],
	data() {
		return {
			currentIndex: 0,
			swiperOption: {
				slidesPerView: 2,
				spaceBetween: 150,
				centeredSlides: true,
				activeColor: null,
			},
		};
	},
	computed: {
		swiper() {
			return this.$refs.swiperGallery.$swiper;
		},
	},
	methods: {
		onSlideChange() {
			this.currentIndex = this.swiper.activeIndex;
		},
		onPaginationClick(index) {
			this.swiper.slideTo(index);
		},
	},
};
</script>
<style lang="scss" scoped>
.gallery {
	position: relative;
	margin: $spacing-1 0;
}

.gallery img {
	margin: 0;
}

.pagination {
	position: relative;
	margin-top: $spacing-0;
}
.caption {
	color: #000;
	font-family: 'Poppins', sans-serif !important;
}
</style>
