<template>
	<v-container class="lazyblock_faq_wrap">
		<v-expansion-panels flat accordion v-model="open">
			<v-expansion-panel v-for="(question, i) in questions" :key="i">
				<hr :class="'solid color-' + color" />
				<v-expansion-panel-header disable-icon-rotate class="expansion-panel-header">
					<template v-slot:actions>
						<v-icon large :class="'color-' + color">{{ open === i ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
					</template>
					<h3 class="faq-title" :class="'color-' + color">
						{{ question.title }}
					</h3>
				</v-expansion-panel-header>
				<v-expansion-panel-content class="answer">
					<span v-html="question.description" />
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-container>
</template>

<script>
export default {
	name: 'lazyblock_faq',
	props: ['block', 'blocks', 'parentBlock'],
	components: {},
	data() {
		return {
			questions: [],
			color: 'aili-blue',
			open: false,
		};
	},
	mounted() {
		this.color = this.block.attrs.color ? this.block.attrs.color : 'aili-blue';
		this.questions = this.parseAttribute(this.block.attrs.questions);
		this.questions.map((question) => {
			question.open = false;
			return question;
		});
	},
};
</script>

<style lang="scss" scoped>
#app .lazyblock_faq_wrap {
	/* margin: $spacing-1 0; */
	@media (max-width: $mobile-m) {
		margin: $spacing-0/2 0;
	}
}
#app .v-expansion-panel {
	background: transparent;
}
@media (max-width: $tablet-m) {
	.expansion-panel-header {
		padding: $spacing-0/2 0;
	}
}
.faq-title {
	max-width: 70%;
	@media (max-width: $tablet-m) {
		max-width: calc(100% - 40px);
	}
}
hr.solid {
	border: 0;
	border-top: 1px solid;
}
</style>
<style lang="scss">
@media (max-width: $tablet-m) {
	.v-expansion-panel-content__wrap {
		padding: 0 0 $spacing-0/2 0;
	}
}
</style>
