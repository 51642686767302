<template>
	<div class="wrapper-slideshow" v-show="slides.length > 0">
		<div class="buttons" v-if="$vuetify.breakpoint.width > 1024">
			<div @click="prevPage" class="arrow-left">
				<Arrow type="left" color="#fff" />
			</div>
			<div @click="nextPage" class="arrow-right">
				<Arrow type="right" color="#fff" />
			</div>
		</div>
		<swiper class="swiper-slideshow" :options="swiperOption" ref="swiperSlideshowFull" @slideChange="onSlideChange">
			<swiper-slide v-for="(slide, i) in slides" :key="i">
				<a :href="slide.url">
					<div class="slide" :style="slides_class[i]" :key="force_rerender + i">
						<div class="description">
							<h2 class="font-size-50 color-aili-white" v-html="slide.title" />

							<Button color="aili-white" :inverted="false" value="Scopri" />
						</div>
					</div>
				</a>
			</swiper-slide>
			<div v-if="slides.length > 1" class="swiper-pagination" slot="pagination"></div>
		</swiper>
	</div>
</template>

<script>
	import Arrow from "@/components/ui/Arrow.vue";
	export default {
		name: "lazyblock_slideshow",
		props: ["block"],
		components: { Arrow },
		data() {
			return {
				active_index: 0,
				//We use force_rerender for lazy loading, using directly slide_class doesn't work
				force_rerender: 0,
				slides: [],
				slides_class: [],
				swiperOption: {
					slidesPerView: 1,
					loop: true,
					pagination: {
						el: ".swiper-pagination",
						clickable: true
					},
					lazy: true
				}
			};
		},

		created() {
			let slides = this.parseAttribute(this.block.attrs.slides);
			slides.map((slide, i) => {
				if (this.active_index === i && slide.image && slide.image.url) {
					this.slides_class.push("background-image:url(" + slide.image.url + ")");
				} else {
					this.slides_class.push(null);
				}
			});
			this.slides = slides;
		},
		computed: {
			swiper() {
				return this.$refs.swiperSlideshowFull ? this.$refs.swiperSlideshowFull.$swiper : null;
			}
		},
		methods: {
			nextPage() {
				this.swiper.slideNext();
			},
			prevPage() {
				this.swiper.slidePrev();
			},
			getStyle(slide) {
				if (!slide.image || !slide.image.url) {
					return "";
				}
				return "background-image:url(" + slide.image.url + ")";
			},
			onSlideChange() {
				this.active_index = this.swiper.activeIndex - 1;
				let slide = this.slides[this.active_index];
				if (!this.slides_class[this.active_index] && slide && slide.image && slide.image.url) {
					this.slides_class[this.active_index] = "background-image:url(" + slide.image.url + ")";
					this.force_rerender++;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.buttons {
		position: absolute;
		z-index: 10;
		bottom: 105px;
		right: 25px;
	}

	.wrapper-slideshow {
		position: relative;
		clear: both;
		margin: 0 auto;
		height: auto;
		max-width: 1500px;

		@media (min-width: 1500) {
			padding-bottom: 70px;
		}
	}

	.swiper-slideshow {
		width: 100%;
		position: relative;
	}

	.categories {
		margin-bottom: 10px;
	}

	.slide {
		cursor: pointer;
		height: 80vh;
		min-height: 500px;
		background-size: cover;
		background-position: center;
		position: relative;
		@media (max-width: $mobile-m) {
			background-position: top center;
		}
	}

	.slide:before {
		content: "";
		background: linear-gradient(0deg, rgb(31, 30, 30) 0%, rgba(31, 30, 30, 0) 100%);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.description {
		position: absolute;
		bottom: 10%;
		left: 10%;
		min-width: 300px;
		@media (max-width: $mobile-m) {
			min-width: 260px;
		}
	}

	.description * {
		color: #fff;
	}

	h2 {
		margin-bottom: $spacing-0;
		max-width: 60vw;
	}
</style>

<style lang="scss">
	//Class inside swiper need to be declared outside of scoped
	.swiper-slideshow {
		.swiper-pagination-bullet-active {
			background-color: $aili-blue;
		}
	}
</style>
