<template>
	<div :id="blocks ? blocks.attrs.section_name : null">
		<div class="search-address">
			<v-autocomplete
				:disabled="!areAddressesLoaded"
				v-model="selectedMunicipality"
				class="bar"
				:color="pageColor"
				:items="municipalities"
				:filter="filterMunicipalitiesByName"
				item-text="name"
				item-value="coordinate"
				hide-no-data
				hide-selected
				placeholder="CAP/Indirizzo"
				@change="onSelectMunicipality"
				v-if="municipalities"
			></v-autocomplete>

			<v-btn class="search-button" :color="pageColor" fab outlined small>
				<v-icon>mdi-magnify</v-icon>
			</v-btn>
		</div>

		<v-row no-gutters>
			<v-checkbox
				v-model="category.selected"
				v-for="(category, i) in categories"
				:key="i"
				:color="pageColor"
				class="category"
			>
				<template v-slot:label>
					<div :class="'color-' + pageColor">{{ category.name }}</div>
				</template>
			</v-checkbox>
		</v-row>

		<v-list
			two-line
			v-if="nearMarkers && nearMarkers.length > 0"
			class="search-list"
		>
			<v-scroll-y-reverse-transition group>
				<template v-for="(nearMarker, i) in nearMarkers">
					<div
						:key="nearMarker.marker.id"
						class="markerList"
						@click="onListMarkerClick(nearMarker)"
					>
						<div v-if="i === 0" class="grey--text">
							Risultati della Ricerca
						</div>
						<div class="shops" :class="'color-' + pageColor" v-html="nearMarker.marker.title">
						</div>

						<v-divider></v-divider>
					</div>
				</template>
			</v-scroll-y-reverse-transition>
		</v-list>
		<div
			:style="{ width: isMapFullScreen || $vuetify.breakpoint.width <= 640 ? '100%' : '70%' }"
			class="mapContainer"
		>
			<GoogleMapLoader
				v-if="areAddressesLoaded"
				ref="map"
				class="map"
				:class="{ marginLeft: isMapFullScreen || $vuetify.breakpoint.width <= 640 }"
				:markers="selectedMarkers"
				:height="$vuetify.breakpoint.width > 1024 ? '700px' : ($vuetify.breakpoint.width > 640 ? '500px' : '400px')"
			>
				<template slot-scope="{ google, map }">
					<GoogleMapMarker
						ref="googleMapMarkers"
						v-for="marker in selectedMarkers"
						:key="marker.id"
						:marker="marker"
						:google="google"
						:map="map"
						:icon="marker.icon"
						@onMarkerClick="onMarkerClick"
					/>
				</template>
			</GoogleMapLoader>
			<div v-else class="loadingWrapper">
				<Loading :position="'absolute'" />
			</div>
		</div>
	</div>
</template>

<script>
import GoogleMapLoader from "@/components/GoogleMap/GoogleMapLoader.vue";
import GoogleMapMarker from "@/components/GoogleMap/GoogleMapMarker.vue";
import Loading from "@/components/ui/Loading.vue";

export default {
	components: {
		GoogleMapLoader,
		GoogleMapMarker,
		Loading
	},
	props: ["block", "blocks", "parentBlock"],
	data() {
		return {
			addresses: [],
			areAddressesLoaded: false,
			categories: null,
			isMapFullScreen: true,
			lastMarkerClicked: null,
			nearMarkers: [],
			selectedMarkers: [],
			selectedMunicipality: null
		};
	},
	watch: {
		categories: {
			deep: true,
			handler(newValueCategories) {
				let googleMarkers;

				if (this.areAddressesLoaded) {
					googleMarkers = this.$refs.googleMapMarkers;
				}

				this.selectedMarkers = [];
				let selectedCategories;

				selectedCategories = newValueCategories.filter(
					category => category.selected
				);

				//if (selectedCategories.length === 0)
					//selectedCategories = this.categories;

				// Controllo ogni indirizzo
				this.addresses.map(address => {
					let isSelected =
						selectedCategories.filter(
							selectedCategory =>
								address["address-category_data"].findIndex(
									currentCategory =>
										currentCategory.slug ===
										selectedCategory.slug
								) > -1
						).length > 0;

					if (isSelected && address.meta_address.location.results && address.meta_address.location.results.length) {
						this.selectedMarkers.push({
							position:
								address.meta_address.location.results[0]
									.geometry.location,
							id: address.slug,
							title: address.title.rendered,
							description: address.meta_address.content,
							tel: address.meta_address.tel,
							email: address.meta_address.email,
							www: address.meta_address.www,
							icon: address.meta_address.icon
								? address.meta_address.icon
								: ""
						});
					} else if (googleMarkers) {
						let markerToDelete = googleMarkers.find(
							marker => marker.$vnode.key == address.slug
						);
						if (markerToDelete) {
							markerToDelete.destroyMarker();
						}
					}
					this.areAddressesLoaded = true;
				});
				setTimeout(
					() => this.onSelectMunicipality(),
					500
				);
			}
		}
	},
	async mounted() {
		await this.getMunicipalities();

		// Tutti gli indirizzi
		let maxPages = null;
		for (
			let currentPage = 1;
			currentPage <= maxPages || !maxPages;
			currentPage++
		) {
			let responseAddresses = await this.$api.get("/wp/v2/address", {
				params: {
					page: currentPage,
					per_page: 100
				}
			});
			responseAddresses.data.map(address => {
				if (address.meta_address && address.meta_address.location && address.meta_address.location.includes("{")) {
					try {
						address.meta_address.location = JSON.parse(
							address.meta_address.location
						);
					} catch (e) {
						console.log(e);
					}
				}
			});
			this.addresses = this.addresses.concat(responseAddresses.data);

			if (!maxPages) {
				maxPages = responseAddresses.headers["x-wp-totalpages"];
			}
		}

		let result = decodeURIComponent(this.block.attrs.categories);
		let selected_term_ids;

		if (result.length > 0) {
			selected_term_ids = JSON.parse(result);
		} else {
			selected_term_ids = [];
		}

		selected_term_ids.map((single_result, index) => {
			selected_term_ids[index] = JSON.parse(
				single_result.category
			).result.id;
		});

		// Le categorie degli indirizzi (con almeno un indirizzo associato)
		let response_address_category = await this.$api.get(
			"/wp/v2/address-category",
			{
				params: {
					hide_empty: true,
					include: selected_term_ids
				}
			}
		);

		response_address_category.data.map(category => {
			category.selected = true;
		});
		this.categories = response_address_category.data;
	},
	methods: {
		filterMunicipalitiesByName(item, queryText) {
			const textOne = item.name.toLowerCase();
			const cap = item.cap;
			const searchText = queryText.toLowerCase();
			return (
				textOne.indexOf(searchText) > -1 || cap.indexOf(searchText) > -1
			);
		},
		onSelectMunicipality() {
			this.nearMarkers = [];
			let map = this.$refs.map;
			if(this.selectedMunicipality){
				map.onChangeMunicipality(
					this.selectedMunicipality.lat,
					this.selectedMunicipality.lng
				);
				let nearMarkersTemp = this.getMarkerOnBound();
				this.isMapFullScreen = nearMarkersTemp.length === 0;
				nearMarkersTemp = nearMarkersTemp.sort(
					this.compareDistanceFromCenter
				);
				for (var i in nearMarkersTemp) {
					let currentMarker = nearMarkersTemp[i];
					setTimeout(
						() => this.nearMarkers.push(currentMarker),
						1000 + 250 * i
					);
				}
			}
		},
		compareDistanceFromCenter(markerComponentA, markerComponentB) {
			let latA = markerComponentA.markerInstance.getPosition().lat();
			let lngA = markerComponentA.markerInstance.getPosition().lng();
			let latB = markerComponentB.markerInstance.getPosition().lat();
			let lngB = markerComponentB.markerInstance.getPosition().lng();
			let centerLat = this.$refs.map.map.getCenter().lat();
			let centerLng = this.$refs.map.map.getCenter().lng();
			let distanceA = this.calcDistance(centerLat, centerLng, latA, lngA);
			let distanceB = this.calcDistance(centerLat, centerLng, latB, lngB);
			return distanceA < distanceB ? -1 : 1;
		},
		calcDistance(fromLat, fromLng, toLat, toLng) {
			let refMap = this.$refs.map;
			return refMap.google.maps.geometry.spherical.computeDistanceBetween(
				new refMap.google.maps.LatLng(fromLat, fromLng),
				new refMap.google.maps.LatLng(toLat, toLng)
			);
		},
		getMarkerOnBound() {
			let map = this.$refs.map;
			let nearMarkers = [];
			this.$refs.googleMapMarkers.map(selectedMarker => {
				if (
					map.map
						.getBounds()
						.contains(selectedMarker.markerInstance.getPosition())
				) {
					nearMarkers.push(selectedMarker);
				}
			});
			return nearMarkers;
		},
		onListMarkerClick(marker) {
			this.onMarkerClick();
			this.$refs.map.google.maps.event.trigger(
				marker.markerInstance,
				"click"
			);
			this.$refs.map.setCenter(
				marker.marker.position.lat,
				marker.marker.position.lng
			);
			this.lastMarkerClicked = marker;
		},
		onMarkerClick(marker) {
			if (this.lastMarkerClicked !== marker) {
				if (this.lastMarkerClicked) {
					this.$refs.map.google.maps.event.trigger(
						this.lastMarkerClicked.markerInstance,
						"click"
					);
				}
				this.lastMarkerClicked = marker;
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.bar {
	padding: 0;
}
#app .category {
	margin: 0;
	margin-right: $spacing-1;
	@media (max-width: $mobile-m) {
		margin-bottom: -10px;
	}
}
.map {
	margin-right: calc(-2.63% - 26px);
	@media (max-width: $mobile-m) {
		margin-right: calc(-#{$spacing-0/2} - 2.5%);
	}
}
.marginLeft {
	margin-left: calc(-2.63% - 25px);
	@media (max-width: $mobile-m) {
		margin-left: calc(-#{$spacing-0/2} - 2.5%);
	}
}
.mapContainer {
	img {
		object-fit: contain !important;
		width: 100%;
		height: 100%;
	}
	transition-property: width;
	transition-duration: 1s;
	float: right;
}
#app .search-button {
	margin-left: $spacing-0;
}
.search-address {
	display: inline-flex;
	width: 100%;
	max-width: 600px;
}
.search-list {
	width: 25%;
	float: left;

	@media (max-width: $mobile-m) {
		width: 100%;
		margin-top: 20px;
	}
}
.shops {
	margin: 16px 0;
}
.loadingWrapper {
	height: 200px;
	position: relative;
}
.markerList {
	cursor: pointer;
}
</style>
