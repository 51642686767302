var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"private-content",class:{
		'private-content-full-width': _vm.parentBlock,
		'private-content-gradient':
			!_vm.loginData.user ||
			(_vm.loginData.user.data.qzr_status && _vm.loginData.user.data.qzr_status.indexOf('active') === -1),
	}},[(
			!_vm.loginData.user ||
				(_vm.loginData.user.data.qzr_status && _vm.loginData.user.data.qzr_status.indexOf('active') === -1)
		)?_c('div',[_c('div',{class:'form background-' + _vm.pageColor + '-light'},[_c('div',{staticClass:"icon-access"},[_c('svg',{staticStyle:{"enable-background":"new 0 0 36 36"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 36 36","xml:space":"preserve"}},[_c('g',{attrs:{"id":"Livello_6"}},[_c('path',{attrs:{"fill":"white","d":"M31,12.9c-0.9-0.9-2-1.6-3.2-2V6.3C27.8,2.8,25,0,21.5,0h-6.7c-3.5,0-6.4,2.8-6.4,6.3v4.6\n\t\tc-1.3,0.4-2.4,1.1-3.4,2v0c-3.3,3.2-3.3,8.4,0,11.6L15.6,35c1.3,1.3,3.4,1.3,4.7,0L31,24.6C34.3,21.3,34.3,16.1,31,12.9z\n\t\tM20.7,28.5h-5l0.9-4.7c-1.1-0.6-1.8-1.6-1.8-2.9c0-1.8,1.5-3.3,3.4-3.3c1.9,0,3.4,1.5,3.4,3.3c0,1.2-0.7,2.3-1.7,2.9L20.7,28.5z\n\t\tM19.2,12.9L18,14.1l-1.2-1.2c-1.6-1.6-3.7-2.4-5.8-2.4V6.3c0-2,1.7-3.6,3.7-3.6h6.7c2,0,3.7,1.6,3.7,3.6v4.2\n\t\tC23,10.5,20.8,11.3,19.2,12.9z"}})])])]),(_vm.paywall)?_c('h2',{class:'font-size-25  color-' + _vm.pageColor,domProps:{"innerHTML":_vm._s(_vm.paywall.title.rendered)}}):_vm._e(),_c('br',{staticClass:"clear"}),_c('div',{staticClass:"form-content"},[_c('div',{staticClass:"login-form"},[_c('LoginForm',{attrs:{"color":_vm.backgroundColor}})],1),_c('div',{class:'login-render-blocks background-' + _vm.pageColor},[_c('RenderBlocks',{attrs:{"blocks":_vm.paywall.blocks}}),_c('Button',{attrs:{"inverted":true,"color":_vm.pageColor,"value":_vm.register.post_title},on:{"click":function($event){return _vm.goTo('Page', { slug: _vm.register.post_name })}}})],1),_c('br',{staticClass:"clear"})])])]):_c('div',{staticClass:"user-active",class:( _obj = {}, _obj['color-' + _vm.pageColor] = true, _obj )},[_c('div',[_c('v-icon',{staticClass:"success-icon",class:'color-' + _vm.pageColor,attrs:{"tiny":""}},[_vm._v(" mdi-check-circle ")]),_vm._v(" Questo contenuto è accessibile perché sei associato ad AILI ")],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }