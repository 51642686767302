<template>
	<div class="subscription-form">
		<Form
			:values="loginData.user.data"
			:loading="loading"
			:confirmedProfileChange="confirmedProfileChange"
			@onSubscribeClick="onSubscribeClick"
		/>
		
		<div class="error-message" v-if="error_message">{{error_message}}</div>
				
		<ChangePassword />
	</div>
</template>

<script>
import Form from "@/components/ui/Form.vue";
import ChangePassword from "@/components/ui/ChangePassword.vue";

export default {
	created() {},
	props: ["block", "blocks", "parentBlock"],
	data() {
		return {
			loading: false,
			error_message: null
		}
	},
	components: {
		Form,
		ChangePassword
	},
	methods: {
		async onSubscribeClick(data) {
			if (data) {
				this.loading = true;
				this.error_message = null;
				this.confirmedProfileChange = false;
			
				data.role = "member";
				data.type = "base";
				data.username = data.email;
				data.scope = JSON.stringify("profile");
				let response = await this.$api.post("/qzr-data/save-fields", data);
				
				if(response.data.result == 'error'){
					this.error_message = Object.values(response.data.data.errors)[0][0];
				} else {
					this.confirmedProfileChange = true;
					setTimeout(() => (this.confirmedProfileChange = false), 3000);
				}
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.edit-password {
	background: #fff;
	position: relative;
	min-height: 270px;
	border-radius: 40px;
	border-bottom-left-radius: 0;
	border-top-right-radius: 0;
}
.first-panel-container {
	display: inline-flex;
	width: 100%;
}
.logo {
	display: block;
	height: 80%;
	margin: 0 auto;
	padding: 20px 0;
}
.logoCard {
	width: 80%;
	background-color: $aili-blue;
	border-radius: 15px;
	border-top-left-radius: 0;
	transform: rotate(-10deg);
}
.logoCard:hover {
	animation: shake 1s;
	animation-iteration-count: infinite;
}
.error-message{
	background-color: #FF0087;
	color: white;
	padding: 25px;
	margin-top: 25px;
}
</style>
