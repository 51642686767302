import { render, staticRenderFns } from "./ChangePassword.vue?vue&type=template&id=7695c81a&scoped=true&"
import script from "./ChangePassword.vue?vue&type=script&lang=js&"
export * from "./ChangePassword.vue?vue&type=script&lang=js&"
import style0 from "./ChangePassword.vue?vue&type=style&index=0&id=7695c81a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7695c81a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VDialog,VFadeTransition,VForm,VTextField})
