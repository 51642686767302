<template>
	<div class="container-last-posts">
		<div class="buttons" v-if="$vuetify.breakpoint.width >= 1024">
			<div @click="prevPage"><Arrow type="left" color="#00cca0" /></div>
			<div @click="nextPage">
				<Arrow @click="prevPage" type="right" color="#00cca0" />
			</div>
		</div>
		<swiper
			class="slider"
			v-if="posts.length > 0"
			ref="swiper_last_pages"
			:options="swiperOption"
		>
			<swiper-slide v-for="(post, i) in posts" :key="i">
				<a
					class="slide"
					:href="getUrl(post.url)"
					:title="post.title"
					@click="goTo('Page', post.url)"
				>
					<div class="title-slide">{{ post.title }}</div>
					<div
						class="image-slide"
						:style="
							'background-image: url(' +
								post.image.sizes.medium.url +
								')'
						"
					></div>
				</a>
			</swiper-slide>
			<div class="swiper-pagination" slot="pagination"></div>
		</swiper>
	</div>
</template>

<script>
import Arrow from "@/components/ui/Arrow.vue";

export default {
	name: "lazyblock_last_pages",
	props: ["block", "parentBlock"],
	data() {
		return {
			posts: [],
			swiperOption: {
				spaceBetween: 20,
				centeredSlides: false,
				loop: true,
				pagination: {
					el: ".swiper-pagination",
					clickable: true
				},
				breakpoints: {
					0: {
						slidesPerView: 3
					},
					1024: {
						slidesPerView: "auto"
					}
				}
			}
		};
	},
	components: {
		Arrow
	},
	async mounted() {
		this.posts = this.parseAttribute(this.block.attrs.pages);
		this.posts.map(el => {
			el.url = el.url.replace(window.location.href, "");
		});
	},
	computed: {
		swiper() {
			return this.$refs.swiper_last_pages.$swiper;
		}
	},
	methods: {
		nextPage() {
			this.swiper.slideNext();
		},
		prevPage() {
			this.swiper.slidePrev();
		}
	}
};
</script>

<style lang="scss" scoped>
.pages {
	padding: $spacing-1;
	background: $aili-white;
	color: $aili-green;
}

.slider {
	position: relative;
	left: calc(50vw + 70px);
	transform: translateX(-50vw);
	width: calc(50vw - 5% - 70px);
	@media (max-width: $tablet-m) {
		left: 0;
		transform: none;
		width: 100%;
	}
}

p {
	margin-top: $spacing-0;
}

.image-slide {
	padding-top: 150%;
	width: 100%;
	background: $aili-green;
	background-size: cover;
	background-position: top center;
}

.slide .title-slide {
	display: inline-block;
	top: 0;
	background: #fff;
	font-weight: normal;
	color: $aili-green;
	font-size: $font-size-14;
}

.slide {
	cursor: pointer;
}

.swiper-slide {
	min-height: 30vw;
	transition: all 0.3s;
	&.swiper-slide-active {
		width: 40%;
	}
	&:not(swiper-slide-active) {
		width: calc(30% - 20px);
	}
	@media (max-width: $tablet-m) {
		//min-height: 150px;
		min-height: 30vw;
	}
	@media (min-width: $desktop-l) {
		//min-height: 600px;
		min-height: 30vw;
	}
}

.swiper-pagination {
	margin-top: 25px;
	position: relative;
}

.buttons {
	display: inline-block;
	width: 90px;
	position: absolute;
	@media (max-width: $tablet-m) {
		position: relative;
		width: 100%;
		display: inline-flex;
		justify-content: space-between;
	}
}
</style>
