<template>
   <div>
       il componente colonne non è ancora sviluppato!
   </div>
</template>

<script>

	export default {
        name: 'CoreColumnsBlock',
        props: ["block"],

    }
</script>