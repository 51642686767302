<template>
	<div
		v-intersect="{
			handler: onIntersect,
			options: { rootMargin: '-49.95% 0% -49.95% 0%' },
		}"
		v-if="rendered"
		ref="wrapper"
		:id="'panel-' + sanitizeTitle(section_name)"
		:data-section-name="section_name"
		:class="`panel background-${background} color-${color} template-${template}`"
		:data-title-panel="block.attrs.name"
		:style="style"
	>
		<Wave color="#fff" v-if="block.attrs.template == 'wave-top' || template == 'image'" position="top" />
		<div>
			<MainColumn>
				<header
					class="panel-header"
					v-if="block.attrs.name || (block.attrs.button_url && block.attrs.template != 'image')"
				>
					<div class="title-wrap">
						<h2 class="font-size-35" v-if="block.attrs.name">
							{{ block.attrs.name }}
						</h2>
					</div>
					<div class="button-wrap">
						<Button
							v-if="block.attrs.button_label && block.attrs.button_url"
							:color="color"
							:href="block.attrs.button_url"
							:value="block.attrs.button_label ? block.attrs.button_label : block.attrs"
						/>
					</div>
				</header>
			</MainColumn>
			<v-row no-gutters v-if="block.innerBlocks !== undefined && template != 'image'">
				<MainColumn v-if="template != 'fullscreen'">
					<RenderBlocks :blocks="block.innerBlocks" :parentBlock="block" />
				</MainColumn>
				<RenderBlocks :blocks="block.innerBlocks" :parentBlock="block" v-else />
			</v-row>
			<Wave :color="getHexColor(background)" v-if="template == 'wave-bottom'" position="bottom" />
		</div>
	</div>
</template>

<script>
import Wave from '@/components/ui/Wave.vue';

export default {
	name: 'qzrstudio_panel',
	props: ['block', 'parentBlock', 'offset', 'padding'],
	components: {
		Wave,
		RenderBlocks: () => import('@/components/blocks/RenderBlocks.vue'),
	},
	data() {
		let image;
		if (this.block.attrs.template == 'image') {
			image = this.block.innerBlocks[0].attrs.url;
		}
		return {
			background: null,
			color: null,
			image: image,
			section_name: null,
			template: null,
			rendered: false,
		};
	},
	mounted() {
		this.refreshAttributes(this.block);
	},
	watch: {
		block: {
			deep: true,
			handler(newBlock) {
				this.refreshAttributes(newBlock);
			},
		},
	},
	computed: {
		style() {

			const style = {
				'padding-left': this.padding,
				'padding-right': this.padding,
				'background-image': 'url(' + this.image + ')',
			};

			if(this.block.attrs.name == 'panel-user_status-pending_renewal_payment') {
				if( this.user_status !== 'pending_renewal_payment') {
					style.display = 'none';
				}
			}

			if( this.block.attrs.name == 'user_status-all') {
				if( this.user_status === 'pending_renewal_payment') {
					style.display = 'none';
				}
			}

			return style;

		}
	},
	methods: {
		refreshAttributes(unparsedBlock) {
			this.background = unparsedBlock.attrs.background ? JSON.parse(unparsedBlock.attrs.background).slug : 'null';

			this.color = unparsedBlock.attrs.color ? JSON.parse(unparsedBlock.attrs.color).slug : 'null';

			this.section_name = unparsedBlock.attrs.section_name
				? this.sanitizeTitle(unparsedBlock.attrs.section_name)
				: '';
			this.template = unparsedBlock.attrs.template ? this.sanitizeTitle(unparsedBlock.attrs.template) : '';
			this.rendered = true;
		},
		onIntersect(entries) {
			if (entries[0].isIntersecting) {
				this.updateProperty({
					property: 'selectedPanel',
					value: this.section_name,
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
#app .panel-header {
	margin-bottom: $spacing-1;
	@media (max-width: 768px) {
		margin-bottom: $spacing-0;
	}
	h2 {
		margin: 0;
	}
	.title-wrap {
		width: calc(100% - 100px);
		display: inline-block;
	}

	.button-wrap {
		width: 100px;
		text-align: right;
		display: inline-block;
	}
}

.panel {
	padding: $spacing-2 $spacing-0;
	color: $aili-white;
	position: relative;
	background-position: top center;

	@media (max-width: $tablet-m) {
		padding: $spacing-1 $spacing-0;
	}

	@media (max-width: $mobile-m) {
		padding: $spacing-1 $spacing-0/2;
	}
}

.panel.template-no-padding {
	padding: 0;
}
.panel.template-less-padding {
	padding: $spacing-0;
}
.panel.template-padding-top {
	padding-bottom: 0;
}
.panel.template-padding-bottom {
	padding-top: 0;
}
.template-image {
	height: 60vh;
	background-size: cover;
	background-repeat: no-repeat;
}

.template-image .canvas {
	top: -8px;
	bottom: auto;
}

.template-padding {
	padding: $spacing-0 $spacing-0;
}
</style>

<style lang="scss">

	[data-title-panel="user_status-pending_renewal_payment"] .title-wrap,
	[data-title-panel="user_status-all"] .title-wrap {
		display: none !important;
	}

</style>