<template>
	<div :class="'block-attention font-size-18 color-' + pageColor">
		<div class="block-attention-header">
			<v-icon x-large :class="'attention-icon color-' + pageColor">
				mdi-alert
			</v-icon>
			<p class="attention-icon-text">
				{{ block.attrs.title ? block.attrs.title : "ATTENZIONE" }}
			</p>
		</div>

		<div
			:class="'content-answer font-size-18 color-' + pageColor"
			v-html="block.attrs.content"
		></div>
		<Button
			:value="block.attrs.label"
			:href="block.attrs.url"
			:color="pageColor"
			v-if="block.attrs.url"
			:inverted="false"
		/>
	</div>
</template>

<script>
export default {
	props: ["block"]
};
</script>

<style lang="scss" scoped>
#app {
	a {
		text-decoration: none;
	}

	.content-question {
		font-weight: bold;
	}

	.content-answer {
		margin-bottom: 15px;
	}

	.block-attention {
		position: relative;
		max-width: 650px;
		padding-left: $spacing-1;
		border-left: 10px solid;
		margin: $spacing-1 0;
		@media (max-width: $tablet-l) {
			padding: $spacing-0;
		}
		.block-attention-header {
			display: inline-block;
			vertical-align: middle;
			width: 100%;

			.attention-icon-text {
				margin: 0;
				left: 100px;
				top: 5px;
				font-weight: bold;
				padding-right: 25px;
				text-transform: uppercase;
			}
		}
	}
}
</style>
