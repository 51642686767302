<template> 
		<RenderBlocks
			:blocks="block.innerBlocks"
			:parentBlock="block"
		/>   
</template>

<style lang="scss" scoped>

</style>

<script>
	export default {
        name: 'core_buttons',
		props: ["block"],
		components: {
			RenderBlocks: () => import("@/components/blocks/RenderBlocks.vue"),
		}
    }
</script>