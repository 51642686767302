<template>
	<div class="carousel_list">
		<v-container class="slider">
			<div class="buttons" v-if="cards.length > 2">
				<Arrow type="left" color="#00cca0" />
				<Arrow type="right" color="#00cca0" />
			</div>
			<swiper
				@slideChange="onSlideChange"
				class="swiper-carousel-list"
				ref="swiperList"
				:options="swiperOption"
			>
				<swiper-slide v-for="(card, i) in cards" :key="i">
					<PanelList class="card" :block="card" />
				</swiper-slide>
				<div
					v-if="
						$vuetify.breakpoint.width < 768 ||
							($vuetify.breakpoint.width < 1024 &&
								cards.length > 2) ||
							cards.length > 3
					"
					class="swiper-pagination"
					slot="pagination"
				></div>
			</swiper>
		</v-container>
	</div>
</template>

<script>
import Arrow from "@/components/ui/Arrow.vue";

export default {
	props: ["block", "blocks", "parentBlock"],
	components: {
		Arrow,
		PanelList: () =>
			import("@/components/blocks/lazyblocks/lazyblock_listpanel.vue")
	},
	data() {
		return {
			currentIndex: 0,
			cards: [],
			swiperOption: {
				slidesPerView: 1,
				//spaceBetween: 60,
				centeredSlides: false,
				pagination: {
					el: ".swiper-pagination",
					clickable: true
				},
				breakpoints: {
					1024: {
						slidesPerView: 3,
						//spaceBetween: 40
					},
					768: {
						slidesPerView: 2,
						//spaceBetween: 20
					}
				}
			}
		};
	},
	mounted() {
		this.cards = this.block.innerBlocks;
	},
	computed: {
		swiper() {
			return this.$refs.swiperList.$swiper;
		}
	},
	methods: {
		onSlideChange() {
			this.currentIndex = this.swiper.activeIndex;
		},
		changeUrl(url) {
			window.location.href = url;
		},
		onPaginationClick(index) {
			this.swiper.slideTo(index);
		},
		nextPage() {
			this.swiper.slideNext();
		},
		prevPage() {
			this.swiper.slidePrev();
		}
	}
};
</script>

<style lang="scss" scoped>
.carousel_list {
	position: relative;
}
#app {
	.swiper-container {
		padding: 0 !important;
	}
}
/* 
.swiper-carousel-list {
	right: 0;
	width: calc(120% - 70px);
	text-align: left;
	margin-left: 100px;
	padding: 50px 0;

	@media (max-width: $tablet-m) {
		margin-left: 0;
		width: 100%;
	}
} */
.swiper-carousel-list {
	right: 0;
	text-align: left;
	margin-left: 100px;
	padding: 50px 0;
	width: calc(120% - 70px);

	@media (max-width: 1600px){
		width: calc(108%);
	}

	@media (max-width: 1500px){
		width: calc(98%);
	}

	@media (max-width: 1280px){
		width: calc(90vw - 75px);
	}

	@media (max-width: $tablet-l) {
		/* margin-left: -$spacing-0/2;
		width: 95vw; */
		padding: 25px 0;
		margin-left: 0;
		width: 100%;
	}
}



.buttons {
	width: 50px;
	position: relative;
	position: absolute;
	top: 50px;
}

.card {
	position: relative;
	overflow: hidden;
	padding: $spacing-0;

	@media (max-width: $tablet-m) {
		padding: $spacing-0;
	}
}
</style>
