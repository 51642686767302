<template>
	<div class="lazyblock_about_us" v-if="elements.length > 0">
		<a
			class="element color-aili-blue"
			:key="i"
			v-for="(element, i) in elements"
			:href="element.url ? element.url : 'javascript:void(0)'"
			:target="element.open_blank ? '_blank' : '_self'"
		>
			<div v-if="element.image" class="image" :style="'background-image:url(' + element.image.url + ')'" />
			<div v-if="element.content" class="description" v-html="element.content" />
			<Arrow type="right" :color="pageColor" />
		</a>
	</div>
</template>

<script>
import Arrow from '@/components/ui/Arrow.vue';

export default {
	props: ['block', 'blocks', 'parentBlock'],
	components: { Arrow },
	data() {
		return {
			elements: [],
		};
	},
	mounted() {
		this.elements = this.parseAttribute(this.block.attrs.links);
	},
};
</script>

<style lang="scss" scoped>
.lazyblock_about_us {
	margin-bottom: $spacing-1;
}
.element {
	display: inline-flex;
	width: 100%;
	text-decoration: none;
	border-bottom: 1px solid #00aeef;
	padding: $spacing-0 0;
	@media (max-width: $tablet-m) {
		display: inline-block;
	}
	.image {
		display: inline-block;
		width: 240px;
		height: 135px;
		background-position: center center;
		background-size: cover;
		margin-right: 25px;
		@media (max-width: $tablet-m) {
			width: 100%;
		}
	}
	.description {
		margin-right: 25px;
		width: calc(100% - 250px);
		@media (max-width: $tablet-m) {
			width: 100%;
			padding: 10px 0;
		}
	}
}
</style>
