const core = require.context("./core", false, /\.vue$/);
const modules = {};
core.keys().forEach((key) => {
	modules[key.replace(/(\.\/|\.vue)/g, "")] = core(key);
});

const lazyblocks = require.context("./lazyblocks", false, /\.vue$/);
lazyblocks.keys().forEach((key) => {
	modules[key.replace(/(\.\/|\.vue)/g, "")] = lazyblocks(key);
});

const qzr = require.context("./qzr", false, /\.vue$/);
qzr.keys().forEach((key) => {
	modules[key.replace(/(\.\/|\.vue)/g, "")] = qzr(key);
});

export default modules;
