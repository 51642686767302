var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"aili-products"},[(_vm.descriptions)?_c('div',{staticClass:"buttons-type"},[_c('div',{staticClass:"button-lactose",class:{
				active: _vm.active_filter == 'naturally_lactose_free' || !_vm.active_filter,
			},on:{"click":function($event){return _vm.updateProducts('naturally_lactose_free')}}},[_c('div',{staticClass:"lactose-icon naturally_lactose_free"}),_c('br',{staticClass:"clear"}),_c('div',{staticClass:"mx-4",domProps:{"innerHTML":_vm._s(_vm.descriptions.naturally_lactose_free.replaceAll('\\', ''))}})]),_c('div',{class:{
				'button-lactose': true,
				active: _vm.active_filter == 'milk_free' || !_vm.active_filter,
			},on:{"click":function($event){return _vm.updateProducts('milk_free')}}},[_c('div',{staticClass:"lactose-icon milk_free"}),_c('br',{staticClass:"clear"}),_c('div',{staticClass:"mx-4",domProps:{"innerHTML":_vm._s(_vm.descriptions.milk_free.replaceAll('\\', ''))}})]),_c('div',{class:{
				'button-lactose': true,
				active: _vm.active_filter == 'lactose_free' || !_vm.active_filter,
			},on:{"click":function($event){return _vm.updateProducts('lactose_free')}}},[_c('div',{staticClass:"lactose-icon lactose_free"}),_c('br',{staticClass:"clear"}),_c('div',{staticClass:"mx-4",domProps:{"innerHTML":_vm._s(_vm.descriptions.lactose_free.replaceAll('\\', ''))}})])]):_vm._e(),_c('v-fade-transition',[(!_vm.loadingProducts)?_c('v-expansion-panels',{attrs:{"accordion":""}},_vm._l((_vm.products),function(category,i){return _c('v-expansion-panel',{key:i},[(category.category)?_c('v-expansion-panel-header',{staticClass:"exp-panel",attrs:{"disable-icon-rotate":"","color":"#fff"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"icon-plus",attrs:{"color":_vm.pageColor}},[_vm._v(" mdi-plus ")]),_c('v-icon',{staticClass:"icon-minus",attrs:{"color":_vm.pageColor}},[_vm._v(" mdi-minus ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"category-image",style:('background-image: url(' + category.image + ')')}),_c('div',{staticClass:"category-info"},[_c('p',{staticClass:"font-size-20 category-title"},[_vm._v(" "+_vm._s(category.category.name)+" ")]),_c('p',{staticClass:"font-size-14 color-gray category-description",domProps:{"innerHTML":_vm._s(category.category.description)}})])]):_vm._e(),(category.category)?_c('v-expansion-panel-content',{staticClass:"exp-panel"},[(_vm.getCategoriesNumber(category.products) === 0)?_c('div',{staticClass:"product pl-1",class:{ 'text-center': _vm.$vuetify.breakpoint.mobile }},[_vm._v(" Nessun prodotto disponibile ")]):_vm._l((category.products),function(product,j){return _c('div',{key:j,staticClass:"product",class:{
							active: product.show,
						}},[(product.show)?_c('div',[_c('div',{class:'lactose-icon small ' + product.qzr_aili_product_type}),_c('p',{domProps:{"innerHTML":_vm._s(
									product.title.rendered +
										(product['aili-product-brand_data'][0]
											? ' ' + product['aili-product-brand_data'][0].name
											: '')
								)}})]):_vm._e()])})],2):_vm._e()],1)}),1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }