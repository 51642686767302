<template>
	<section>
		<div class="lazyblock-recipe">
			<div class="categories color-aili-black tiny">
				<div class="post-type">Ricetta</div>

				<div class="separator"></div>
				<div class="category" v-for="(category, j) in parentBlock['recipe-category_data']" :key="j">
					<span class="category-name">{{ category.name }}</span>
					<span class="category-comma" v-if="j < parentBlock['recipe-category_data'].length - 1">,</span>
				</div>
			</div>
			<h1 class="font-size-50 color-aili-pink" v-html="parentBlock.title.rendered" />

			<div class="author" @click="goTo('Chef', { slug: parentBlock.author_data.slug })">
				<div
					class="author-image-single"
					:style="'background-image: url(' + parentBlock.author_data.images.thumbnail + ')'"
				></div>

				<h5 class="author-name color-aili-black font-size-15">
					{{ parentBlock.author_data.first_name[0] + ' ' + parentBlock.author_data.last_name[0] }}
				</h5>
			</div>
		</div>
		<div class="recipe-image" :style="'background-image: url(' + parentBlock.featured_media_data.large + ')'">
			<Wave class="wave" color="#fff" position="top" />
		</div>
		<div class="lazyblock-recipe">
			<div :class="'description font-size-20 a-' + pageColor" v-html="block.attrs.content"></div>
		</div>
		<div class="panel panel-ingredients" ref="ingredients">
			<Wave position="top" color="#fff" />
			<v-row class="padding-ingredients">
				<v-col cols="3">
					<RecipeIcon v-if="block.attrs.duration" :value="block.attrs.duration" name="duration" />
					<RecipeIcon v-if="block.attrs.people" :value="block.attrs.people" name="people" />
					<RecipeIcon v-if="block.attrs.difficulty" :value="block.attrs.difficulty" name="difficulty" />
				</v-col>
				<v-col cols="6">
					<h2 class="color-aili-pink font-size-25">
						Ingredienti
					</h2>
					<div class="ingredients">
						<div
							class="ingredient"
							v-for="(ingredient, i) in parseAttribute(block.attrs.ingredients)"
							:key="i"
						>
							{{ '(' + ingredient.quantity + ') ' }}{{ ingredient.name }}
						</div>
					</div>
				</v-col>
			</v-row>
		</div>
		<div class="lazyblock-recipe">
			<div v-if="parseAttribute(block.attrs.steps).length > 0">
				<v-row class="title">
					<v-col offset="3" cols="9">
						<h4 class="color-aili-pink font-size-35">
							Preparazione
						</h4>
					</v-col>
				</v-row>

				<div v-for="(step, i) in parseAttribute(block.attrs.steps)" :key="i">
					<v-row class="step">
						<v-col cols="2" class="text-right">
							<div class="number">
								<span class="font-weight-bold">{{ i + 1 }}</span>
							</div>
						</v-col>
						<v-col class="step-preparation" cols="9" sm="8" v-html="step.content"></v-col>
					</v-row>
				</div>
			</div>
			<div v-if="block.innerBlocks.length > 0 && block.innerBlocks[0].attrs.text != ''" class="notes-wrapper">
				<v-row>
					<v-col offset="1" offset-sm="3" cols="10" sm="8">
						<h2 class="color-aili-pink font-size-25">Note</h2>
					</v-col>
				</v-row>
				<v-row>
					<v-col offset="1" offset-sm="3" cols="10" sm="8">
						<RenderBlocks :blocks="block.innerBlocks" />
					</v-col>
				</v-row>
			</div>
		</div>
	</section>
</template>

<script>
import RecipeIcon from '@/components/ui/RecipeIcon.vue';
import Wave from '@/components/ui/Wave.vue';
export default {
	name: 'lazyblock_recipe',
	props: ['block', 'blocks', 'parentBlock'],
	components: {
		RecipeIcon,
		Wave,
		RenderBlocks: () => import('@/components/blocks/RenderBlocks.vue'),
	},
};
</script>

<style lang="scss" scoped>
.categories {
  margin-bottom: 10px;
}
#app h1 {
  padding: 0;
  margin-bottom: 0 !important;
  @media (max-width: $tablet-m) {
    line-height: 1.2;
  }
}

.author {
  display: inline-block;
  width: 100%;
  margin: 0;
  margin-top: 10px;
  text-decoration: none;

  .author-image-single {
    float: left;
    width: 40px;
    height: 40px;
    margin-right: 14px;
    background-size: contain;
    border-radius: 100%;
  }
  .author-name {
    height: 40px;
    line-height: 40px !important;
  }
}

#app .step-preparation {
  padding-top: $spacing-0;
  padding-left: 8.33%;
  border-top: 1px solid #fa81b9;
}
#app h4 {
  margin-bottom: $spacing-0;
}
.number {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  background: $aili-pink-light;
  border-radius: 90px;
}
.number span {
  position: absolute;
  top: 50%;
  left: 50%;
  color: $aili-pink;
  transform: translate(-50%, -50%);
}

.container {
  background: $aili-white;
}

.lazyblock-recipe {
  padding: $spacing-2;
  padding-bottom: $spacing-1;
  background: $aili-white;
  @media (max-width: $tablet-m) {
    padding: $spacing-1 $spacing-0;
  }
}

.title .col {
  @media (max-width: $tablet-m) {
    margin-left: $spacing-0;
  }
}

.description {
  max-width: 850px;
  margin-bottom: $spacing-1;
  word-wrap: break-word;
}

.lazyblock-recipe .row {
  margin-bottom: 2rem;
}

.panel {
  padding: $spacing-1 $spacing-2;
  background: $aili-pink-light;
  @media (max-width: $tablet-m) {
    padding: $spacing-1 $spacing-0;
  }
}

h2 {
  font-size: $font-size-20 !important;
}

.panel-ingredients {
  position: relative;
  @media (max-width: $tablet-l) {
    .row {
      display: inline-block;
      width: 100%;
    }
    .col {
      width: 100%;
      max-width: 100%;
    }
  }
}

.ingredients {
  margin-top: 1rem;
}

.ingredient {
  padding: .5rem 0;
  border-bottom: 1px solid #f7258578;
}
.recipe-image {
  position: relative;
  width: 100%;
  padding-top: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.wave {
  top: -8px !important;
}
.notes-wrapper {
  padding-top: $spacing-1;
  .col-sm-8,
  .col-10 {
    padding-left: 0;
  }
}
</style>

<style>
.lazyblock_core_free .lazyblock_private_content {
	margin-bottom: 50px;
	width: 118%;
	margin-left: -9%;
	margin-top: 50px;
}
</style>
